import React, { useState } from "react";
import style from "./reviewQuestionnaires.module.scss";
import Layout from "components/layout/Layout";
import NoDataMessage from "components/noDataBlock/noDataMessage";
import { SearchInput } from "components/UI/searchInput/searchInput";
import { Filter } from "components/UI/filter/filter";
import { useGetUserQuestionnaires } from "actions/questionnaireActions";
import { ReviewQuestionnaireTableRow } from "./reviewQuestionnaireTableRow/reviewQuestionnaireTableRow";
import { Modal } from "components/modal/modal";
import Questionnaire, { QuestionnaireDataParams } from "components/questionnaire/questionnaire";
import { useGetLawyers } from "actions/lawyerActions";
import SingleFilterDropDown, { OptionItem } from "components/UI/singleFilterDropDown/singleFilterDropDown";
import { IntStatus } from "utils/commonFunctions";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";
const sortOptions: OptionItem[] = [
  {
    label: "Date Submitted - Newest to Oldest",
    value: { orderBy: "submitted", sortOrder: "DESC" },
  },
  {
    label: "Date Submitted - Oldest to Newest",
    value: { orderBy: "submitted", sortOrder: "ASC" },
  },
  {
    label: "Matter Description - A to Z",
    value: { orderBy: "fileDesc", sortOrder: "ASC" },
  },
  {
    label: "Matter Description - Z to A",
    value: { orderBy: "fileDesc", sortOrder: "DESC" },
  },
  {
    label: "Matter Number - Newest to Oldest",
    value: { orderBy: "fileNo", sortOrder: "DESC" },
  },
  {
    label: "Matter Number - Oldest to Newest",
    value: { orderBy: "fileNo", sortOrder: "ASC" },
  },
];

const statusOptions = [
  {id: '4', label: 'Approved'},
  {id: '2', label: 'Pending Review'},
  {id: '3', label: 'Rejected'},
];

export const ReviewQuestionnaires = () => {
  const [isStatusVisible, setIsStatusVisible] = useState(false);
  const [searchWord, setSearchWord] = useState<string>('');
  const [orderBy, setOrderBy] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [intStatus, setIntStatus] = useState<IntStatus[]>();
  const [responsibleLawyerID, setResponsibleLawyerID] = useState<string>(''); 
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState<boolean>(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<QuestionnaireDataParams>();
  const [hideQuestionnaireModalHeader, setHideQuestionnaireModalHeader] = useState(false);
  
  const { data: questionnaireLawyers, error: questionnairelawyersError } = useGetLawyers();
  const lawyerOptions: OptionItem[] = questionnaireLawyers?.map(lawyer => ({
    label: lawyer.label,
    value: lawyer.id
  })) ?? [];
  
  const { 
    data: questionnaireData, 
    error: questionnnaireError,
    mutate: mutateQuestionnaires,
    noDataFound: noQuestionnaireDataFound,
    size,
    setSize,
    isValidating,
    hasMore,
  } = useGetUserQuestionnaires({ rowNumber: 100, orderBy, sortOrder, intStatus, responsibleLawyerID, searchWord });

  const onSearch = (value: string) => {
    setSearchWord(value);
    resetData();
  };

  const handleSortChange = (value: any) => {
    setOrderBy(value?.orderBy || "");
    setSortOrder(value?.sortOrder || "");
    resetData();
  };

  const intStatusChange = (selectedOptions: any[]) => {
    setIntStatus(selectedOptions);
    resetData();
  };

  const onClickViewQuestionnaire = (clickedQuestionnaire: any) => {
    setSelectedQuestionnaire({
      title: clickedQuestionnaire.questionnaire,
      fileId: clickedQuestionnaire.fileID, 
      questionnaireId: clickedQuestionnaire.questionaireID, 
      intStatus: clickedQuestionnaire.intStatus,
      contId: clickedQuestionnaire.contID 
    });
    setShowQuestionnaireModal(true);
  };

  const closeQuestionnaire = () => {
    setShowQuestionnaireModal(false);
  };

  const resetData = () => {
    setSize(1);
    mutateQuestionnaires();
  };

  const loadMore = () => {
    setSize(prevSize => prevSize + 1);
  };

  const [loader] = useInfiniteScroll(loadMore, hasMore, [orderBy, sortOrder, searchWord, responsibleLawyerID, intStatus]);
  

  return (
    <Layout>
      <div className={style.company}>
        <h2>Your Questionnaires</h2>
      </div>
      <div className={style.filterButtons}>
        <SingleFilterDropDown 
          id='questionnaireFilter'
          label="Sort Questionnaires"
          optionItems={sortOptions} 
          onOptionSelected={handleSortChange}
        />
        <SingleFilterDropDown 
          id={'responsibleLawyerFilter'}
          label="Responsible Lawyer"
          onOptionSelected={(value) => setResponsibleLawyerID(value || '')}
          optionItems={lawyerOptions}
          mobileText="Responsible Lawyer"        
        />
        <Filter
          id="statusFilter"
          text="Status"
          isVisible={isStatusVisible}
          setVisible={setIsStatusVisible}
          onCheckboxChange={(selectedOptions) => intStatusChange(selectedOptions)}
          filterOptions={statusOptions}
          mobileText="Status"
          className={style.sort}
        />
        <SearchInput placeholder="Try 'work permit'" onChange={onSearch} className={style.searchBar}/>
      </div>
      <div className={style.clientTableContainerWrapper}>
        <div className={style.clientTableContainer}>
          {noQuestionnaireDataFound ? <NoDataMessage title={"No questionnaires available."} /> : (
            <div>
              <table className={style.clientTable}>
                <thead>
                  <tr>
                    <th>Matter No.</th>
                    <th>Client Name</th>
                    <th>Matter Description</th>
                    <th>Questionnaire</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {!noQuestionnaireDataFound && !isValidating && questionnaireData.map((data) => (
                  <ReviewQuestionnaireTableRow
                    key={`${data.fileID}-${data.questionaireID}-${data.questionnaire}`}
                    data={data}
                    onClick={() => onClickViewQuestionnaire(data)}
                  />
                ))}
                {isValidating && questionnaireData && size > 1 && <tr><td><span>Loading...</span></td></tr>}
              </table>
              {size === 1 && isValidating && <LoadingContainer loadingLabel="Loading questionnaires..." />}
              <div ref={loader} style={{ height: '1px', backgroundColor: 'transparent' }} />
            </div>
          )}
        </div>
      </div>
      <Modal 
        id='reviewQuestionnaireModal'
        title={`${(selectedQuestionnaire ? selectedQuestionnaire.title : 'null')}`} 
        show={showQuestionnaireModal} 
        onClose={closeQuestionnaire} 
        className={style.questionnaireModal}
        hideModalHeader={hideQuestionnaireModalHeader}
      >
        <Questionnaire 
          selectedQuestionnaire={selectedQuestionnaire as QuestionnaireDataParams} 
          closeQuestionnaire={closeQuestionnaire} 
          mutateQuestionnaires={mutateQuestionnaires}
          isReview={true}
          setHideQuestionnaireModalHeader={setHideQuestionnaireModalHeader}
        />
      </Modal>
    </Layout>
  );
};
