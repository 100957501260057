import { useEffect, useState } from 'react';
import { verifyTwoFactor } from 'actions/userActions';
import { useSetAppState, useAppState, useSetUserState, useMergeUserState, useMergeAppState } from 'context';
import { withRouter, useHistory } from 'react-router-dom';
import { Button } from 'components/UI/button/button';
import { TextField } from 'components/UI/textField/textField';
import styles from './twoFactorForm.module.scss';
import { RoutePaths, StandardUserRoutePaths } from 'app/routing/routing';
import { useForm, SubmitHandler } from "react-hook-form";
import HideIcon from 'components/icons/hide';
import { isUserClient, isUserStandard } from 'utils/userFunctions';
import { jwtData } from 'config/config';
import { useKeyDownTrigger } from 'hooks/useKeyDownTrigger';
import ShowIcon from 'components/icons/show';

export interface VerifyFormFields {
  twoFactorCode: string;
}

const useOnVerification = () => {
  const mergeUserState = useMergeUserState();
  const mergeAppState = useMergeAppState();

  const onSubmit: SubmitHandler<VerifyFormFields> = async (data) => {
    const { twoFactorCode } = data;
    const token = localStorage.getItem(jwtData.authToken);
    const results = await verifyTwoFactor({twoFactorCode, authToken: token});
    if (results) {
      const { userType } = results;
      mergeUserState({ userType });

      localStorage.setItem(jwtData.authToken, results.authResult.authToken);
      localStorage.setItem(jwtData.authTokenExpiry, `${results.authResult.expiresIn}`);
      const { refreshToken, refreshExpiresIn } = results.authResult;

      if (refreshToken) {
        localStorage.setItem(jwtData.refreshToken, refreshToken);
      }
    
      if (refreshExpiresIn) {
        localStorage.setItem(jwtData.refreshTokenExpiry, `${refreshExpiresIn}`);
      }
    }

    mergeAppState({ Authenticated: true, Authenticating: false });
      
    return results;
  };

  return onSubmit;
};


export const TwoFactorForm = () => {
  const { register, handleSubmit, formState: { errors }, setError, setValue, trigger } = useForm<VerifyFormFields>();
  const appState = useAppState();
  const history = useHistory();
  const [showingPassword, setShowingPassword] = useState(false);

  const onVerification = useOnVerification();

  const onChange = (value: string) => {
    setValue("twoFactorCode", value);
    trigger("twoFactorCode");
  };

  const onSubmit = async (values: VerifyFormFields) => {
    if (!appState.Authenticated) {
      try {
        await onVerification(values);
        if(isUserClient()){
          history.push(RoutePaths.Dashboard);
        }
        if(isUserStandard()){
          history.push(StandardUserRoutePaths.ReviewQuestionnaires);
        }
      } catch (err) {
        setError('twoFactorCode', {
          type: 'manual',
          message: 'Invalid code',
        });
      }
    }
  };

  const onShowHidePassword = () => {
    setShowingPassword(!showingPassword);
  };

  useKeyDownTrigger(handleSubmit(onSubmit));
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.passwordLabel}>Enter the 6-digit code you see in the app.</div>
      <div className={styles.passwordContainer}>
        <TextField
          id={"twoFactorCode"}
          register={register}
          type={ showingPassword ? 'text' : 'password' }
          placeholder="••••••••"
          label=""
          className={styles.field}
          labelClassName={styles.labels}
          errors={errors}
          autoFocus={true}
          onChange={onChange}
          validationSchema={{ required: "Code is required" }}
        />
        {showingPassword ? <HideIcon onClick={onShowHidePassword} /> : <ShowIcon onClick={onShowHidePassword} />}
      </div>
      <Button id={"verify2FA"} className={styles.submitButton} type="submit">
        Verify
      </Button>
    </form>
  );
};