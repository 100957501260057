import { getFileType } from "utils/commonFunctions";
import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";
import { fileUploadSize } from "./actionConts";
import useSWRInfinite from "swr/infinite";
import useSWR from "swr";

export interface DocumentFilters {
  searchWord?: string;
  pageNumber?: number;
  rowNumber: number;
  orderBy: string;
  sortOrder: string;
  docType: string[];
  fileId?: string;
}

export interface Document {
  fileNo: number;
  docId: string;
  docFileName: string;
  applicantName: string;
  fileDesc: string;
  docDesc: string;
  created: string;
  expiry: string;
  docType: string;
  cdDesc: string;
}

export interface SMBDocument {
  image: string;
  format: string;
}

export interface DocumentName {
  name: string;
  fieldId: string;
  permanent: boolean;
  path?: string;
}

export interface DocumentNames {
  localDocuments: DocumentName[];
  uploadedDocuments: DocumentName[];
}

export interface DocumentNotifications {
  numberOfNewDocuments: number;
}

const TextFileType = 'txt';


const getDocuments = async (params: DocumentFilters): Promise<Document[]> => {
  const result = await base.get(apiEndpoints.document, { params });
  return result.data;
};

const useGetDocuments = (params: DocumentFilters) => {
  const getKey = (pageIndex: number, previousPageData: Document[] | null) => {
    if (previousPageData && previousPageData.length === 0) return null; // reached the end
    return [apiEndpoints.applications, { ...params, pageNumber: pageIndex + 1 }];
  };

  const fetcher = async ([url, params]: [string, DocumentFilters]) => {
    const response = await getDocuments(params);
    return response;
  };

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: false,
  });

  const documentsData = data ? data.filter(document => document !== null).flat() : [];
  const hasMore = data && data[data.length - 1]?.length === params.rowNumber;

  return {
    data: documentsData,
    error,
    mutate,
    isValidating,
    size,
    setSize,
    hasMore,
    noDataFound: documentsData && documentsData.length === 0,
  };
};

const getSMBDocument = async (filePath: string, isFullPath?: boolean) : Promise<any | null> => {
  try {
    const fileType = getFileType(filePath);
    let result;
    if (fileType !== TextFileType) {
      result = await base.getBlob(apiEndpoints.smbDocument, {
        params: {
          filePath,
          isFullPath
        },
      });
    } else {
      result = await base.get(apiEndpoints.smbDocument, {
        params: {
          filePath,
          isFullPath
        },
      });
    }
    let file: SMBDocument | null = null;
    const header = result.headers['content-type'].trim().toLowerCase();


    if(fileType === TextFileType) {
      file = {
        format: header,
        image: result.data
      };
    } else { 
      file = {
        format: header,
        image: URL.createObjectURL(result.data)
      };
    }
    return file;
  } catch (error) {
    return null;
  }
};

// File needs to be base 64 encoded
const uploadSMBDocument = async (fileNo: string, docName: string, file: string, questionId?: string) => {
  try {
    const result = await base.post(apiEndpoints.smbDocument, {
      fileNo,
      docExt: getFileType(docName),
      docName,
      file, 
    },
    {
      maxBodyLength: fileUploadSize,
      maxContentLength: fileUploadSize
    });
    result.data.questionId = questionId;
    return result.data;
  } catch (error) {
    console.error('Error uploading document', error);
    throw error;
  }
};

const getDocumentNamesFromPaths = async (paths: string[]) => {
  try {
    const result = await base.get(apiEndpoints.getDocumentNamesFromPaths, { 
      params: { documents: paths }
    });
    return result.data;
  } catch (error) {
    return null;
  }
};

const getDocumentNotifications = async (): Promise<DocumentNotifications> => {
  const result = await base.get(`${apiEndpoints.documentNotifications}`);
  return result.data;
};

const useGetDocumentNotifications = () => {
  const { data, error, mutate } = useSWR(
    `${apiEndpoints.documentNotifications}`,
    () => getDocumentNotifications()
  );

  return {
    data,
    error,
    mutate,
    isLoading: !error && !data,
  };
};

const markNotificationsAsRead = async (): Promise<void> => {
  await base.post(apiEndpoints.documentNotifications, {});
};

const resetNotificationsToUnread = async (): Promise<void> => {
  await base.post(apiEndpoints.documentNotificationsReset, {});
};

export {
  useGetDocuments,
  getSMBDocument,
  uploadSMBDocument,
  getDocumentNamesFromPaths,
  useGetDocumentNotifications,
  markNotificationsAsRead,
  resetNotificationsToUnread,
};