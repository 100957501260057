import classNames from "classnames";
import CalendarIcon from "components/icons/calendar";
import AnimatedDatePicker from "components/UI/animatedDatePicker/datePicker";
import React, { FC, useEffect, useState } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import {
  DateFormat,
  formatDateToString,
  parseHTML,
} from "utils/commonFunctions";
import style from "./datePicker.module.scss";
import useScreenWidth from "hooks/useScreenWidth";
import { isValid, parse, format, isMatch } from "date-fns";

interface DatePickerProps {
  id: string;
  label?: string;
  value?: Date;
  className?: string;
  labelClassName?: string;
  register?: UseFormRegister<any>;
  errors?: FieldErrors<any>;
  locked?: boolean;
  onChange?: (date: string) => void;
  maxDate?: Date;
  minDate?: Date;
  isTablePreviewQuestion?: boolean;
  accepted?: boolean;
  showErrorStyle?: boolean;
  placeholder?: string;
}

const DatePicker: FC<DatePickerProps> = ({
  id,
  label,
  value,
  className,
  labelClassName,
  errors,
  locked,
  onChange,
  maxDate,
  minDate,
  isTablePreviewQuestion,
  accepted,
  showErrorStyle,
  placeholder,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Date | undefined>(value);
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [hasCalendarChanged, setHasCalendarChanged] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const width = useScreenWidth();

  const displayError =
    errorMessage || (errors && id && errors[id]?.message as string);
  const hasErrors = showErrorStyle || (!!displayError && !isTablePreviewQuestion);

  const formatDate = (date: Date) => {
    return format(date, "yyyy-MM-dd");
  };

  const onCalendarChange = (selectedDate?: Date) => {
    if (!selectedDate) {
      setShowCalendar(false);
      return;
    }
    const dateString = formatDateToString(
      selectedDate,
      DateFormat.YYYY_MM_DD_dashes
    );
    setSelectedValue(selectedDate);
    setInputValue(formatDate(selectedDate));
    setHasCalendarChanged(true);
    setErrorMessage(null);
    setShowCalendar(false);
    onChange && onChange(dateString);
  };

  const handleTextFieldClick = (e: any) => {
    if (!locked) {
      setShowCalendar(true);
    }
  };

  useEffect(() => {
    if (!hasCalendarChanged) {
      setSelectedValue(value);
    }
  }, [value]);
  
  function autoFormatDate(input: string): string {
    if (/^\d{4}-$/.test(input)) {
      return input;
    }
    if (/^\d{4}-\d-$/.test(input)) {
      const year = input.substring(0, 4);
      const monthDigit = input.substring(5, 6);
      return `${year}-${monthDigit.padStart(2, '0')}-`;
    }
    if (/^\d{4}-\d{2}-$/.test(input)) {
      return input;
    }
    const digits = input.replace(/\D/g, "");
    const year = digits.slice(0, 4);
    const month = digits.slice(4, 6);
    const day = digits.slice(6, 8);
    let formatted = year;
    if (digits.length > 4) {
      formatted += "-" + month;
    }
    if (digits.length > 6) {
      formatted += "-" + day;
    }
    return formatted;
  }
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const formatted = autoFormatDate(rawValue);
    
    // Clear both states when input is empty
    if (!rawValue) {
      setSelectedValue(undefined);
      setInputValue("");
      setErrorMessage(null);
      onChange && onChange("");
      return;
    }

    if (formatted.length === 10) {
      if (isMatch(formatted, "yyyy-MM-dd")) {
        const parsedDate = parse(formatted, "yyyy-MM-dd", new Date());
        if (isValid(parsedDate) && format(parsedDate, "yyyy-MM-dd") === formatted) {
          setSelectedValue(parsedDate);
          setInputValue(formatted);
          setErrorMessage(null);
          onChange && onChange(formatted);
          return;
        }
      }
    }
    setSelectedValue(undefined);
    setInputValue(formatted);
    setErrorMessage(null);
    onChange && onChange(formatted);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if (!value) {
      setErrorMessage(null);
      return;
    }

    if (/^\d{4}-\d{2}-\d$/.test(value)) {
      const parts = value.split("-");
      parts[2] = parts[2].padStart(2, "0");
      value = parts.join("-");
      setInputValue(value);
      onChange && onChange(value);
    }

    const formatRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!formatRegex.test(value)) {
      setErrorMessage("Incorrect format. Please use YYYY-MM-DD");
      return;
    }


    const parsedDate = parse(value, "yyyy-MM-dd", new Date());
    if (!isValid(parsedDate) || format(parsedDate, "yyyy-MM-dd") !== value) {
      setErrorMessage("Invalid date. Please enter a real date with the following format: YYYY-MM-DD");
      return;
    }

    if (minDate && parsedDate.getTime() < minDate.getTime()) {
      setErrorMessage(`Date must be on or after ${format(minDate, "yyyy-MM-dd")}`);
      return;
    }

    if (maxDate && parsedDate.getTime() > maxDate.getTime()) {
      setErrorMessage(`Date must be on or before ${format(maxDate, "yyyy-MM-dd")}`);
      return;
    }

    setErrorMessage(null);
  };

  return (
    <div
      className={classNames(
        style.wrapperDiv,
        isTablePreviewQuestion && style.tableQuestionMobileStyle
      )}
    >
      {showCalendar && !locked && (
        <AnimatedDatePicker
          close={onCalendarChange}
          value={selectedValue}
          maxDate={maxDate}
          minDate={minDate}
        />
      )}

      <div className={classNames(style.inputDiv)}>
        <div
          className={classNames(
            style.dateContainer,
            locked && style.locked,
            hasErrors && style.errorBorder
          )}
        >
          {label && !isTablePreviewQuestion && (
            <label
              htmlFor={label}
              className={classNames(
                style.label,
                labelClassName,
                hasErrors && style.errorText
              )}
            >
              {parseHTML(label)}
            </label>
          )}

          <input
            type="text"
            className={classNames(style.dateText)}
            placeholder={placeholder ? placeholder : label}
            value={inputValue ?? (selectedValue ? formatDate(selectedValue) : "")}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={locked}
            onClick={(e) => e.currentTarget.select()}
          />
        </div>
        {(!isTablePreviewQuestion || (isTablePreviewQuestion && width >= 720)) &&
        <div
          className={classNames(
            style.calendarIcon,
            hasErrors && style.errorBorder
          )}
          onClick={handleTextFieldClick}
        >
          <CalendarIcon />
        </div>
        }
      </div>

      {displayError && !isTablePreviewQuestion && (
        <div>
          <span id={`${id}-error`} className={style.error}>
            {displayError}
          </span>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
