import { AuthTokenPayload } from 'actions/lib/jwtActions';
import { jwtData } from 'config/config';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { io, Socket } from 'socket.io-client';
import { useAppState } from 'context';

const useAuthenticationSocket = (): void => {
  const appState = useAppState();
  
  useEffect(() => {
    // Don't create socket during 2FA or authentication process
    if (appState.Authenticating || appState.twoFactorRequired || !appState.Authenticated) {
      return;
    }
    
    const token = localStorage.getItem(jwtData.authToken);
    if (!token) return;

    try {
      const decodedToken = jwtDecode<AuthTokenPayload>(token);
      const currentPasswordVersion = decodedToken.passwordVersion;

      // Use the configured API URL instead of hardcoded localhost
      const baseUrl = process.env.REACT_APP_API_URL || '';
      // Remove any trailing slashes from the API URL
      const socketUrl = baseUrl.replace(/\/$/, '');

      console.log('Creating socket connection...');
      const socket: Socket = io(socketUrl, {
        auth: { token },
        transports: ['websocket', 'polling'],
        path: '/socket.io',
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 20000, // Increased timeout to allow for slower connections
        autoConnect: true,
        withCredentials: true
      });

      socket.on('connect', () => {
        console.log('Socket connected successfully with ID:', socket.id);
      });

      socket.on('disconnect', (reason) => {
        console.log('Socket disconnected:', reason);
      });

      socket.on('passwordVersionUpdated', (newPasswordVersion: string) => {
        if (currentPasswordVersion !== newPasswordVersion) {
          localStorage.clear();
          window.location.href = '/login';
        }
      });

      socket.on('forceLogout', (data) => {
        console.log('Force logout event received:', data);
        localStorage.clear();
        window.location.href = '/login?message=Your session has been terminated';
      });

      socket.on('connect_error', (err) => {
        console.error('Socket connection error:', err);
      });

      return () => {
        console.log('Cleaning up socket connection...');
        socket.removeAllListeners();
        socket.disconnect();
      };
      
    } catch (error) {
      console.error('Error setting up socket connection:', error);
    }
  }, [appState.Authenticated, appState.Authenticating, appState.twoFactorRequired]);
};

export default useAuthenticationSocket;