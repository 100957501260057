export const baseAPIUrl = process.env.REACT_APP_API_URL;

// Jwt specific data 
export const jwtData = { 
  refreshUrl: 'refreshAuth',
  authToken: 'authToken',
  refreshToken: 'refreshToken',
  authTokenExpiry: 'authTokenExpiry',
  refreshTokenExpiry: 'refreshTokenExpiry',
  expiryOffset: 60*1000,
};

export const apiEndpoints = {
  login: 'login',
  validateMagicLink: 'validateMagicLink',
  users: 'users',
  usersMe: 'users/me',
  updateUser: 'updateUser',
  forgotPasswordEmail: 'forgotPasswordEmail',
  forgotPassword: 'forgotPassword',
  updatePassword: 'updatePassword',
  healthCheckApi: 'healthCheck/api',
  healthCheckDatabase: 'healthCheck/database',
  notifications: 'notifications',
  documentNotifications: 'document/notifications',
  documentNotificationsReset: 'document/notifications/reset',
  markNotificationAsRead: 'markNotificationAsRead',
  company: 'company',
  search: 'search',
  applications: 'applications',
  questionnaire: 'questionnaire',
  questionnaireInfo: 'questionnaireInfo',
  userQuestionnaire: 'userQuestionnaireInfo',
  saveQuestionnaire: 'saveQuestionnaire',
  applicationInfo: 'applicationInfo',
  createApplication: 'createApplication',
  createApplicationRequest: 'createApplicationRequest',
  reviewApplicationRequest: 'reviewApplicationRequest',
  getApplicationRequestsList: 'getApplicationRequestsList',
  getApplicationRequest: 'getApplicationRequest',
  document: 'document',
  smbDocument: 'smbDocument',
  getDocumentNamesFromPaths: 'getDocumentNamesFromPaths',
  locations: 'locations',
  lawyers: 'lawyers',
  applicationNumbers: 'applicationNumbers',
  setup2FA: 'users/setup2FA',
  verify2FA: 'users/verify2FA',
  hasVerified2fa: 'users/hasVerified2FA',
  fileNumbers: 'fileNumbers',
  client: 'client',
  caseAnalysts: 'caseAnalysts',
  departments: 'departments',
  matterTypes: 'matterTypes',
  passwordVersion: 'user/passwordVersion',
  existingEmailCheck: 'user/existingEmailCheck',
};
