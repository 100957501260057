import styles from './checkbox.module.scss';
import classNames from "classnames";
import { useState } from 'react';
import { UseFormRegister, FieldErrors, RegisterOptions } from 'react-hook-form';
import { parseHTML } from 'utils/commonFunctions';

interface ICheckboxProps {
  id: string;
  label?: string;
  value?: boolean;
  register?: UseFormRegister<any>; 
  errors?: FieldErrors<any>;
  validationSchema?: RegisterOptions;
  className?: string;
  locked?: boolean;
  onChange?: (checked: string) => void; 
  isTablePreviewQuestion?: boolean;
  accepted?: boolean;
  showErrorStyle?: boolean;
}

export const Checkbox = ({
  id,
  label,
  value,
  register,
  errors,
  validationSchema,
  className,
  locked,
  onChange,
  isTablePreviewQuestion,
  accepted,
  showErrorStyle,
}: ICheckboxProps) => {
  const [checkedValue, setCheckedValue] = useState(value);
  const hasErrors = showErrorStyle || ((errors && errors[id] || accepted === false) && !isTablePreviewQuestion);

  const handleChange = (checked: boolean) => {
    setCheckedValue(checked);
    if (onChange) {
      onChange(`${checked}`);
    }
  };
  return (
    <div className={classNames(styles.checkboxContainer, className)}>
      <label className={styles.label}>
        <input 
          id={id}
          type="checkbox"
          checked={checkedValue}
          {...(register ? register(id as `${string}`, validationSchema) : {})}
          onChange={(e) => handleChange(e.target.checked)}
          className={styles.checkboxInput}
          disabled={locked}
        />
        <span className={classNames(styles.customCheckbox, hasErrors && styles.errorBorder)}></span>
        <div className={classNames(styles.text, hasErrors && styles.redText)}>{parseHTML(label)}</div>
      </label>
    </div>
  );
};
