import { ReactNode } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import styles from "./button.module.scss";

export interface IButtonProps {
  id?: string;
  children?: ReactNode | string;
  style?: "primary" | "secondary" | "gradient" | "upload" | "none";
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
  mobileText?: string;
}

interface LinkButtonProps extends IButtonProps {
  to: string;
}

export const LinkButton = ({
  id,
  to,
  style = "primary",
  children,
  className,
}: LinkButtonProps) => {
  return (
    <Link
      id={id}
      to={to}
      className={classNames(
        styles.button,
        styles.linkButton,
        style && styles[style],
        className
      )}
    >
      {children}
    </Link>
  );
};

export const Button = ({
  id,
  style = "primary",
  children,
  className,
  onClick = () => undefined,
  disabled = false,
  type = "button",
  mobileText,
}: IButtonProps) => {
  const space = "\u00a0";

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(e);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.stopPropagation();
      onClick(e as unknown as React.MouseEvent);
    }
  };

  if (style === "none") {
    return (
      <div
        id={id}
        className={className}
        tabIndex={0}
        role="button"
        onClick={handleClick}
        onKeyPress={handleKeyPress}
        aria-disabled={disabled}
      >
        {children}
      </div>
    );
  }
  
  if (style === "gradient") {
    return (
      <button
        id={id}
        className={classNames(styles.button, style && styles[style], className, disabled && styles.disabledStyle)}
        {...{
          disabled,
          onClick: handleClick,
          type,
        }}
      >
        <div className={styles.innerGradientButtonBG}></div>
        {mobileText && (
          <div className={classNames(styles.mobileOnly, styles.buttonLabel)}>
            {mobileText}
          </div>
        )}
        <div className={classNames(mobileText && styles.desktopOnly, styles.buttonLabel)}>
          {children}
        </div>
      </button>
    );
  }

  return (
    <button
      id={id}
      className={classNames(styles.button, style && styles[style], className)}
      {...{
        disabled,
        onClick: handleClick,
        type,
      }}
    >
      {mobileText && (
        <div className={styles.mobileOnly}>
          {style === "upload" && `+${space}${space}`}
          {mobileText}
        </div>
      )}
      <div className={mobileText ? styles.desktopOnly : ""}>
        {style === "upload" && `+${space}${space}`}
        {children}
      </div>
    </button>
  );
};
