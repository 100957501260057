import { createContextStore } from './base';
import { authResult, userIsAuthorized } from 'actions/userActions';

export interface AppInfo {
  Authenticated?: boolean; 
  Authenticating?: boolean;
  RefreshAuthenticating?: boolean;
  LoginResult?: authResult;
  isMagicLinkAccess?: boolean;
  searchQuery?: string;
  twoFactorRequired?: boolean;
}

const initialState: AppInfo = {
  Authenticated: userIsAuthorized(),
  Authenticating: false,
  RefreshAuthenticating: false,
  LoginResult: undefined,
  isMagicLinkAccess: false,
  searchQuery: undefined,
  twoFactorRequired: false,
};

const [ Provider, useState, useSetState, useMergeState ] = createContextStore<AppInfo>(initialState);

export {
  Provider as AppContextProvider,
  useState as useAppState,
  useSetState as useSetAppState,
  useMergeState as useMergeAppState,
};
