import { userIsAuthorized, fetchUser, validateMagicLink } from "actions/userActions";
import { RoutePaths } from "app/routing/routing";
import { useAppState, useMergeAppState } from "context/app";
import { useMergeUserState } from "context/user";
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { mutate } from "swr";
import { apiEndpoints } from "../config/config";

export const useGetAuth = () => {
  const mergeAppState = useMergeAppState();
  const mergeUserState = useMergeUserState();
  const appState = useAppState();
  const history = useHistory();
  const location = useLocation();
  
  const justAuthenticated = useRef(false);
  const isCheckingAuth = useRef(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleMagicLink = async (authToken: string): Promise<void> => {
    try {
      const tokens = await validateMagicLink(authToken);
      justAuthenticated.current = true;

      mergeAppState({
        Authenticated: true,
        Authenticating: false,
        RefreshAuthenticating: false,
        LoginResult: tokens,
        isMagicLinkAccess: true,
        twoFactorRequired: false
      });
      
      // Force user data refresh before proceeding
      await fetchUser().then(result => {
        if (result) {
          mergeUserState(result);
        }
      });
      
    } catch (error) {
      // If the magic link is invalid/used and the user is already authenticated
      if (appState.Authenticated) {
        // If user is already authenticated, keep them logged in and continue to the URL
        mergeAppState({ 
          Authenticating: false,
          isMagicLinkAccess: false
        });
      } else {
        // If not authenticated and magic link is invalid, redirect to login
        mergeAppState({ 
          Authenticating: false,
          Authenticated: false,
          LoginResult: undefined,
          isMagicLinkAccess: false,
          twoFactorRequired: false
        });
        history.replace(RoutePaths.Login);
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const authToken = searchParams.get("authToken");

    if (authToken && !appState.Authenticating) {
      mergeAppState({ Authenticating: true });
      handleMagicLink(authToken)
        .then(() => {
          // Clear the authToken from the URL to avoid authentication loops
          const targetPath = location.pathname;
          
          // Clear all SWR caches to force refetching data
          mutate(apiEndpoints.usersMe);
          
          // Clear specific dashboard-related caches
          [
            apiEndpoints.company,
            apiEndpoints.applications,
            apiEndpoints.document,
            apiEndpoints.questionnaire,
            apiEndpoints.client
          ].forEach(endpoint => {
            mutate(endpoint);
          });
          
          // Force page reload to ensure all components get fresh data
          setTimeout(() => {
            window.location.href = targetPath;
          }, 100);
        })
        .catch((error) => {
          // Error handling is done in handleMagicLink
          console.error("Magic link processing error:", error);
        });
    }
  }, [location.search]);

  useEffect(() => {
    // Don't do anything during the authentication process
    if (appState.Authenticating) {
      return;
    }
    
    // Don't start checks if we're already checking
    if (appState.RefreshAuthenticating) {
      return;
    }
    
    // If we just logged in, mark as authenticated but skip periodic checks for a while
    if (appState.Authenticated && !intervalRef.current) {
      justAuthenticated.current = true;
      
      const delay = 10000;
      
      setTimeout(() => {
        // Only set up interval if it doesn't exist yet
        if (!intervalRef.current) {
          intervalRef.current = setInterval(() => {
            // Skip check if we're already checking or just authenticated
            if (isCheckingAuth.current || justAuthenticated.current) {
              justAuthenticated.current = false;
              return;
            }
            
            isCheckingAuth.current = true;
            
            const userAuth = userIsAuthorized();
            
            if (appState.Authenticated !== userAuth) {
              if (userAuth) {
                fetchUser()
                  .then(result => {
                    mergeUserState(result);
                    mergeAppState({ Authenticated: true });
                  })
                  .catch(() => {
                    mergeAppState({ Authenticated: false });
                  })
                  .finally(() => {
                    isCheckingAuth.current = false;
                  });
              } else {
                mergeAppState({ Authenticated: false });
                isCheckingAuth.current = false;
              }
            } else {
              isCheckingAuth.current = false;
            }
          }, 60000);
        }
      }, delay);
    }
    
    // If not authenticated and no interval, set up normal auth check
    if (!appState.Authenticated && !intervalRef.current && !isCheckingAuth.current) {
      isCheckingAuth.current = true;
      
      const userAuth = userIsAuthorized();
      if (userAuth) {
        fetchUser()
          .then(result => {
            mergeUserState(result);
            mergeAppState({ Authenticated: true });
          })
          .catch(() => {
            // Do nothing on error
          })
          .finally(() => {
            isCheckingAuth.current = false;
          });
      } else {
        isCheckingAuth.current = false;
      }
      
      // Set up interval for future checks
      intervalRef.current = setInterval(() => {
        // Skip check if we're already checking
        if (isCheckingAuth.current) return;
        
        isCheckingAuth.current = true;
        const userAuth = userIsAuthorized();
        
        if (appState.Authenticated !== userAuth) {
          if (userAuth) {
            fetchUser()
              .then(result => {
                mergeUserState(result);
                mergeAppState({ Authenticated: true });
              })
              .catch(() => {
                // Do nothing on error
              })
              .finally(() => {
                isCheckingAuth.current = false;
              });
          } else {
            // Only update if we were previously authenticated
            if (appState.Authenticated) {
              mergeAppState({ Authenticated: false });
            }
            isCheckingAuth.current = false;
          }
        } else {
          isCheckingAuth.current = false;
        }
      }, 60000);
    }
    
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [appState.Authenticated, appState.Authenticating, appState.RefreshAuthenticating]);

  return null;
};
  
const AuthHandler: React.FC = () => {
  useGetAuth();
  return null;
};

export default AuthHandler; 