import { useState } from 'react';
import { updatePassword } from 'actions/userActions';
import { useAppState } from 'context';
import { withRouter } from 'react-router-dom';
import { Button } from 'components/UI/button/button';
import styles from './resetPasswordForm.module.scss';
import { useForm } from "react-hook-form";
import PasswordComponent from 'components/UI/passwordComponent/passwordComponent';
import { allowedCharactersRegex, hasDigit, hasSpecialCharacter, hasUpperCase } from 'utils/regexValidations';

export interface UpdatePasswordFieldProps {
  newPassword: string;
  oldPassword: string;
  retryNewPassword?: string;
}

export enum PasswordFields {
  OldPassword = 'oldPassword',
  NewPassword = 'newPassword',
  RetryNewPassword = 'retryNewPassword',
}

export const ResetPasswordFormComponent = () => {
  const { register, handleSubmit, formState: { errors }, setError, clearErrors, reset } = useForm<UpdatePasswordFieldProps>();
  const appState = useAppState();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSubmit = async (values: UpdatePasswordFieldProps) => {
    if(values.retryNewPassword !== values.newPassword) {
      setError(PasswordFields.RetryNewPassword, {
        type: "required",
        message: `Your passwords do not match`
      });
      return;
    }
    if(values.oldPassword === "") {
      setError(PasswordFields.OldPassword, {
        type: "required",
        message: `Old password is required`
      });
      return;
    }
    if(values.oldPassword === values.newPassword) {
      setError(PasswordFields.NewPassword, {
        type: "required",
        message: `You can not set your new password to your old password`
      });
      return;
    }
    if(values.newPassword === "") {
      setError(PasswordFields.NewPassword, {
        type: "required",
        message: `New password is required`
      });
      return;
    }
    clearErrors(PasswordFields.OldPassword);
    clearErrors(PasswordFields.NewPassword);
    clearErrors(PasswordFields.RetryNewPassword);
    try{
      const response: any = await updatePassword({oldPassword: values.oldPassword, newPassword: values.newPassword});
      if (response.status === 200) {
        setSuccessMessage("Password successfully updated");
        setErrorMessage("");
        reset()
      } else {
        setSuccessMessage("");
        setErrorMessage("Password update failed");
      }
    }catch(e: any){
      setSuccessMessage("");
      setErrorMessage(e.message);
    }
  };

  const onInputChange = (value: string, name: string) => {
    setSuccessMessage("");
    setErrorMessage(''); 

    if (name as PasswordFields === PasswordFields.OldPassword){
      if(value.length == 0){
        setError(name as PasswordFields, {
          type: "required",
          message: "Old password is required"
        });
        return;
      }
      else{
        clearErrors(name as PasswordFields);
      }
      return;
    }
    
    if(value.length < 8){
      setError(name as PasswordFields, {
        type: "required",
        message: "Password must be at least 8 characters long"
      });
      return;
    }

    if (!allowedCharactersRegex.test(value)) {
      setError(name as PasswordFields, {
        type: "pattern",
        message: "Password contains invalid characters "
      });
      return;
    }

    if (!hasDigit.test(value)) {
      setError(name as PasswordFields, {
        type: "pattern",
        message: "Password must contain at least one digit"
      });
      return;
    }

    if (!hasSpecialCharacter.test(value)) {
      setError(name as PasswordFields, {
        type: "pattern",
        message: "Password must contain at least one special character (!@#$%^&*)"
      });
      return;
    }

    if (!hasUpperCase.test(value)) {
      setError(name as PasswordFields, {
        type: "pattern",
        message: "Password must contain at least one uppercase letter"
      });
      return;
    }

    if (value.length > 50) {
      setError(name as PasswordFields, {
        type: "required",
        message: `password must be less than 50 characters long`
      });
      return;
    }
    clearErrors(name as PasswordFields);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.resetPassForm}>

      <PasswordComponent
        register={register}
        name="oldPassword"
        placeholder="••••••••"
        className={styles.field}
        label="Old Password"
        errors={errors}
        onChange={(input) => onInputChange(input, "oldPassword")}
      />

      <PasswordComponent
        register={register}
        name="newPassword"
        placeholder="••••••••"
        className={styles.field}
        label="New Password"
        errors={errors}
        onChange={(input) => onInputChange(input, "newPassword")}
      />

      <PasswordComponent
        register={register}
        name="retryNewPassword"
        placeholder="••••••••"
        className={styles.field}
        label="Re-enter New Password"
        errors={errors}
        onChange={(input) => onInputChange(input, "retryNewPassword")}
      />

      <Button id={'savePassword'} className={styles.submitButton} type="submit">
        Save Password
      </Button>
      
      <div className={styles.instruct}>
        <ul>
          <li>Must be different from previously used passwords.</li>
          <li>At least 8 characters long.</li>
          <li>At least 1 number and 1 special character.</li>
          <li>At least 1 uppercase character.</li>
        </ul>
      </div>
      <div className={styles.success}>
        {successMessage}
      </div>
      <div className={styles.error}>
        {errorMessage}
      </div>
    </form>
  );
};

export const ResetPasswordForm = withRouter(ResetPasswordFormComponent);
