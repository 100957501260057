import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import { format } from "date-fns";
import { useForm } from "react-hook-form";

import Layout from "components/layout/Layout";
import { DashboardAppRow } from "components/dashboardAppRow/dashboardAppRow";
import { DashboardDocumentRow } from "components/dashboardDocumentRow/dashboardDocumentRow";
import NoDataMessage from "components/noDataBlock/noDataMessage";
import { Filter } from "components/UI/filter/filter";
import { SearchInput } from "components/UI/searchInput/searchInput";
import { Modal } from "components/modal/modal";
import Questionnaire, { QuestionnaireDataParams } from "components/questionnaire/questionnaire";
import { BackArrow } from "components/UI/backArrow/backArrow";
import SingleFilterDropDown, { OptionItem } from "components/UI/singleFilterDropDown/singleFilterDropDown";
import { Button } from "components/UI/button/button";
import FileUpload, { DocumentAdditionParams, FileUploadStyle } from "components/UI/FileUpload/fileUpload";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";

import { useGetQuestionnaires, Questionnaire as QuestionnaireType } from "actions/questionnaireActions";
import { useGetApplicationInfo } from "actions/applicationsActions";
import {
  uploadSMBDocument,
  useGetDocuments,
  getSMBDocument,
  useGetDocumentNotifications,
  markNotificationsAsRead,
  SMBDocument,
} from "actions/documentActions";

import { fileToBase64, parseFileExtensions, IntStatus } from "utils/commonFunctions";
import { AllowedExtensions, SMBFileTypes, formatImage } from "utils/imageFunctions";

import useInfiniteScroll from "hooks/useInfiniteScroll";

import styles from "./appPage.module.scss";
import { DocumentFormFields } from "pages/documents/documents";
import { RoutePaths } from "app/routing/routing";

const filterOptions = [
  { id: "1", label: "Active" },
  { id: "4", label: "Approved" },
  { id: "0", label: "Get Started" },
  { id: "2", label: "Pending Review" },
  { id: "3", label: "Rejected" },
];

const sortOptions: OptionItem[] = [
  {
    label: "Status - Alphabetical (A to Z)",
    value: { orderBy: "status", sortOrder: "ASC" },
  },
  {
    label: "Status - Alphabetical (Z to A)",
    value: { orderBy: "status", sortOrder: "DESC" },
  },
];

const documentsFilterOptions = [
  { id: "IMAGE", label: "Images" },
  { id: "PDF", label: "PDF" },
  { id: "SPREADSHEET", label: "Spreadsheet" },
  { id: "DOCUMENT", label: "Word/Documents" },
  { id: "SHELL", label: "Other" },
];

const documentsSortOptions: OptionItem[] = [
  {
    label: "Date Uploaded - Newest to Oldest",
    value: { orderBy: "created", sortOrder: "DESC" },
  },
  {
    label: "Date Uploaded - Oldest to Newest",
    value: { orderBy: "created", sortOrder: "ASC" },
  },
  {
    label: "Document Name - Alphabetical (A to Z)",
    value: { orderBy: "docDesc", sortOrder: "ASC" },
  },
  {
    label: "Document Name - Alphabetical (Z to A)",
    value: { orderBy: "docDesc", sortOrder: "DESC" },
  },
  {
    label: "Matter Number - Newest to Oldest",
    value: { orderBy: "fileNo", sortOrder: "DESC" },
  },
  {
    label: "Matter Number - Oldest to Newest",
    value: { orderBy: "fileNo", sortOrder: "ASC" },
  },
];

export const Application = () => {
  // Router and navigation
  const { matterId, companyId, questionnaireId, contId } = useParams<{
    matterId: string;
    companyId: string;
    questionnaireId?: string;
    contId?: string;
  }>();

  const history = useHistory();
  const location = useLocation<{ fileDesc: string; fileNo: string }>();

  // Questionnaire Tab States
  const [activeTab, setActiveTab] = useState<"questionnaire" | "documents">("questionnaire");
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<QuestionnaireDataParams>();
  const [hideQuestionnaireModalHeader, setHideQuestionnaireModalHeader] = useState(false);
  const [isFilterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [orderBy, setOrderBy] = useState("status");
  const [sortOrder, setSortOrder] = useState("");
  const [filter, setFilter] = useState<string[]>([]);

  // Documents Tab States
  const [docSearchWord, setDocSearchWord] = useState("");
  const [docOrderBy, setDocOrderBy] = useState("");
  const [docSortOrder, setDocSortOrder] = useState("");
  const [docType, setDocType] = useState<string[]>([]);
  const [isDocFilterDropdownVisible, setDocFilterDropdownVisible] = useState(false);
  const [showUploadDocumentsModal, setShowUploadDocumentsModal] = useState(false);
  const [showUploadDocumentsModalElements, setShowUploadDocumentsModalElements] = useState(true);
  const [selectedFileId, setSelectedFileId] = useState<string>(matterId || "");
  const [uploadQueue, setUploadQueue] = useState<{ files: File[]; success: boolean }>({
    files: [],
    success: false,
  });
  const [uploadMessages, setUploadMessages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [smbDocument, setSmbDocument] = useState<SMBDocument | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  // File upload and form
  const { register, formState: { errors }, setError, clearErrors } = useForm<DocumentFormFields>();
  const UPLOAD_FIELD_ID = "documentPageFileUpload" as keyof DocumentFormFields;

  // Data fetching hooks
  const { data: notificationData, mutate: mutateNotifications } = useGetDocumentNotifications();
  const { data: applicationInfo } = useGetApplicationInfo(matterId);
  const {
    data: questionnairesData,
    mutate: mutateQuestionnaires,
    noDataFound: noQuestionnairesDataFound,
  } = useGetQuestionnaires({ fileId: matterId, filter, orderBy, sortOrder, searchWord });
  
  const {
    data: documentData,
    mutate: mutateDocuments,
    noDataFound: noDocumentDataFound,
    isValidating,
    size,
    setSize,
    hasMore,
  } = useGetDocuments({
    searchWord: docSearchWord,
    rowNumber: 100,
    orderBy: docOrderBy,
    sortOrder: docSortOrder,
    docType,
    fileId: matterId,
  });

  useEffect(() => {
    if (!questionnairesData.length || !questionnaireId) return;
    
    const questionnaire = questionnairesData.find(q => 
      q.questionnaireId === parseInt(questionnaireId) && 
      (!contId || q.contId === contId)
    );
    
    if (questionnaire) {
      onClickViewQuestionnaire(questionnaire);
    }
  }, [questionnairesData, questionnaireId, contId]);

  useEffect(() => {
    if (matterId) {
      setSelectedFileId(matterId);
    }
  }, [matterId]);

  const handleTabClick = async (tab: "questionnaire" | "documents") => {
    setActiveTab(tab);
    if (tab === "documents" && (notificationData?.numberOfNewDocuments ?? 0) > 0) {
      try {
        await markNotificationsAsRead();
        mutateNotifications({ numberOfNewDocuments: 0 }, false);
      } catch (error) {
        console.error("Failed to mark notifications as read:", error);
        mutateNotifications();
      }
    }
  };

  /* Handlers - Questionnaire Tab */
  const handleSortChange = (value: any) => {
    setOrderBy(value?.orderBy || "");
    setSortOrder(value?.sortOrder || "");
    const currentPath = location.pathname;
    const index = currentPath.indexOf(RoutePaths.Questionnaire);
    if (index !== -1) {
      history.push(currentPath.substring(0, index));
      setShowQuestionnaireModal(false);
    }
    mutateQuestionnaires();
  };

  const onSearch = (value: string) => {
    setSearchWord(value);
  };

  const onFilterChange = (selectedOptions: string[]) => {
    setFilter(selectedOptions);
  };

  const onClickViewQuestionnaire = (clickedQuestionnaire: QuestionnaireType) => {
    const path = `${location.pathname.split(RoutePaths.Questionnaire)[0]}${RoutePaths.Questionnaire}/${clickedQuestionnaire.questionnaireId}/${clickedQuestionnaire.contId}`;
    history.push(path);

    setSelectedQuestionnaire({
      title: clickedQuestionnaire.name,
      fileId: clickedQuestionnaire.fileId,
      questionnaireId: clickedQuestionnaire.questionnaireId,
      intStatus: clickedQuestionnaire.intStatus,
      contId: clickedQuestionnaire.contId
    });
    
    setShowQuestionnaireModal(true);
  };

  const closeQuestionnaire = () => {
    const currentPath = location.pathname;
    const index = currentPath.indexOf(RoutePaths.Questionnaire);
    const newPath = index !== -1 ? currentPath.substring(0, index) : currentPath;
    history.push(newPath);
    setShowQuestionnaireModal(false);
    setSelectedQuestionnaire(undefined);
  };

  const generateAddress = (appInfo: typeof applicationInfo) => {
    if (!appInfo?.streetName) return "n/a";
    const addressParts = [
      appInfo.streetName,
      appInfo.unitNumber,
      appInfo.city,
      appInfo.provinceState,
    ].filter(Boolean);
    return addressParts.join(", ");
  };

  /* Handlers - Documents Tab */
  const handleDocSortChange = (value: any) => {
    setDocOrderBy(value?.orderBy || "");
    setDocSortOrder(value?.sortOrder || "");
    resetDocData();
  };

  const handleDocFilterChange = (selectedOptions: string[]) => {
    setDocType(selectedOptions);
    resetDocData();
  };

  const onDocSearch = (value: string) => {
    setDocSearchWord(value);
    resetDocData();
  };

  const resetDocData = () => {
    setSize(1);
    mutateDocuments();
  };

  const openUploadDocumentsModal = () => {
    setUploadMessages([]);
    setShowUploadDocumentsModal(true);
    setShowUploadDocumentsModalElements(true);
  };

  const handleCloseDocumentsModal = () => {
    setShowUploadDocumentsModal(false);
    setSelectedFileId(matterId || "");
    clearErrors(UPLOAD_FIELD_ID);
    setUploadQueue({ files: [], success: false });
  };

  const uploadDocument = async (file: File): Promise<{ messages: string[] }> => {
    if (!selectedFileId || !file) return { messages: [] };
    const rawBase64 = await fileToBase64(file);
    const base64RemoveExtension = rawBase64.split(",")[1];
    try {
      const response = await uploadSMBDocument(selectedFileId, file.name, base64RemoveExtension);
      return { messages: response.messages || [] };
    } catch (error) {
      return { messages: ["An error occurred during the upload. Please try again."] };
    }
  };

  const resetUploadQueue = () => {
    setUploadQueue({ files: [], success: false });
  };

  const addDocuments = async ({ files }: DocumentAdditionParams) => {
    try {
      clearErrors(matterId as keyof DocumentFormFields);
      setUploadMessages([]);
      setIsUploading(true);
      const { supportedFiles, error } = parseFileExtensions(files, [
        ...AllowedExtensions,
        SMBFileTypes.zipFile.value,
      ]);
      setUploadQueue({ files: supportedFiles, success: false });
      if (error) {
        setError(matterId as keyof DocumentFormFields, error);
      }
      if (supportedFiles.length === 0) return;
      const uploadResults = await Promise.all(
        supportedFiles.map(async (file) => uploadDocument(file))
      );
      const backendErrorMessages = uploadResults.flatMap((result) => result.messages || []);
      if (backendErrorMessages.length > 0) {
        setUploadMessages(backendErrorMessages);
        setShowUploadDocumentsModalElements(true);
      } else {
        setUploadQueue({ files: [], success: true });
        setShowUploadDocumentsModalElements(false);
      }
    } catch (error) {
      setError(matterId as keyof DocumentFormFields, {
        type: "fileType",
        message: `Upload failed. Please try again.`,
      });
      resetUploadQueue();
      setShowUploadDocumentsModalElements(true);
    } finally {
      setIsUploading(false);
      mutateDocuments();
    }
  };

  const handleDownload = async (doc: any) => {
    try {
      setIsDownloading(true);
      const smbDoc = await getSMBDocument(doc.docFileName);
      if (!smbDoc?.image) {
        console.error("SMB document or image not found");
        return;
      }
      const url = smbDoc.image.startsWith("blob:")
        ? smbDoc.image
        : URL.createObjectURL(
          new Blob(
            [
              smbDoc.format.startsWith("text/") || smbDoc.format.includes("json")
                ? smbDoc.image
                : new Uint8Array(
                  atob(smbDoc.image.split(",")[1])
                    .split("")
                    .map((c) => c.charCodeAt(0))
                ),
            ],
            { type: smbDoc.format }
          )
        );
      const link = document.createElement("a");
      link.href = url;
      link.download = doc.docDesc || "document";
      link.click();
      if (!smbDoc.image.startsWith("blob:")) {
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Download failed", error);
    } finally {
      setIsDownloading(false);
    }
  };

  const onClickDocument = async (doc: any) => {
    try {
      setIsLoadingPreview(true);
      setSelectedDocument(doc);
      setShowDocumentModal(true);
      const smbDoc = await getSMBDocument(doc.docFileName);
      setSmbDocument(smbDoc);
    } catch (error) {
      console.error("Failed to load document preview", error);
    } finally {
      setIsLoadingPreview(false);
    }
  };

  const image = formatImage(smbDocument?.format, smbDocument?.image);

  const loadMore = () => {
    setSize((prevSize) => prevSize + 1);
  };

  const [loader] = useInfiniteScroll(loadMore, hasMore, [
    docOrderBy,
    docSortOrder,
    docSearchWord,
    docType,
    activeTab,
  ]);

  return (
    <Layout>
      <div className={styles.applicationInfo}>
        <div className={styles.backArrow}>
          <BackArrow to={`${RoutePaths.Company}/${companyId}`} />
        </div>
        <h2>
          {location.state?.fileNo || applicationInfo?.fileNo} - {location.state?.fileDesc || applicationInfo?.fileDesc}
        </h2>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.information}>
          <div className={styles.inform}>
            <div className={styles.headerName}>
              {applicationInfo?.applicantName || "n/a"}
            </div>
            <div className={styles.subName}>Address</div>
            <div className={styles.answer}>
              {generateAddress(applicationInfo) || "n/a"}
            </div>
            <div className={styles.mobileContactRow}>
              <div>
                <div className={styles.subName}>Phone Number</div>
                <div className={styles.answer}>
                  {applicationInfo?.applicantNumber || "n/a"}
                </div>
              </div>
              <div>
                <div className={styles.subName}>Email</div>
                <div className={styles.answer}>
                  {applicationInfo?.applicantEmail || "n/a"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.lawyerInfo}>
          <div className={styles.inform}>
            <div className={styles.subName}>Lawyer</div>
            <div className={styles.clientHeaderName}>
              {applicationInfo?.responsibleName || "n/a"}
            </div>
            <div className={styles.subName}>Phone Number</div>
            <div className={styles.answerPhone}>
              {applicationInfo?.responsiblePhone || "n/a"}
            </div>
            <div className={styles.subName}>Email</div>
            <div className={styles.answer}>
              {applicationInfo?.responsibleEmail || "n/a"}
            </div>
          </div>
        </div>
        <div className={styles.analyst}>
          <div className={styles.inform}>
            <div className={styles.subName}>Case Analyst</div>
            <div className={styles.clientHeaderName}>
              {applicationInfo?.analystName || "n/a"}
            </div>
            <div className={styles.subName}>Phone Number</div>
            <div className={styles.answerPhone}>
              {applicationInfo?.analystPhone || "n/a"}
            </div>
            <div className={styles.subName}>Email</div>
            <div className={styles.answer}>
              {applicationInfo?.analystEmail || "n/a"}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.tabsContainerWrapper}>
        <div className={styles.tabs}>
          <button
            className={classNames(styles.tab, activeTab === "questionnaire" && styles.activeTab)}
            onClick={() => handleTabClick("questionnaire")}
          >
            <h5 className={styles.tabHeader}>Questionnaires</h5>
          </button>
          <button
            className={classNames(styles.tab, activeTab === "documents" && styles.activeTab)}
            onClick={() => handleTabClick("documents")}
          >
            <h5 className={styles.tabHeader}>
              {(notificationData?.numberOfNewDocuments ?? 0) > 0 && (
                <span className={styles.notificationBadge}>
                  {notificationData?.numberOfNewDocuments}
                </span>
              )}
              Documents
            </h5>
          </button>
        </div>

        {activeTab === "questionnaire" && (
          <div className={styles.section}>
            <div className={styles.filterButtons}>
              <Filter
                id="filter-questionnaires"
                text="Filter Questionnaires"
                mobileText="Filter"
                filterOptions={filterOptions}
                className={styles.filter}
                onCheckboxChange={onFilterChange}
                isVisible={isFilterDropdownVisible}
                setVisible={setFilterDropdownVisible}
              />
              <SingleFilterDropDown
                id="sortQuestionnaires"
                label="Sort Questionnaires"
                optionItems={sortOptions}
                onOptionSelected={handleSortChange}
                mobileText="Sort"
                dropDownDirectionClass={styles.dropDownDirectionClass}
              />
              <SearchInput
                id="searchQuestionnaires"
                placeholder="Try 'questionnaire'"
                onChange={onSearch}
                className={styles.searchBar}
              />
            </div>

            <div className={styles.clientTableContainerWrapper}>
              {noQuestionnairesDataFound ? (
                <NoDataMessage title="No questionnaires available." />
              ) : (
                <div className={styles.clientTableContainer}>
                  <table className={classNames(styles.clientTable, styles.questionnairesTable)}>
                    <tbody>
                      {questionnairesData.map((row: QuestionnaireType, index: number) => {
                        return (
                          <DashboardAppRow
                            key={`questionnaire-${row.questionnaireId}-${row.contId}`}
                            index={index}
                            header={row.name}
                            progress={row.progress || 0}
                            intStatus={row.intStatus as IntStatus}
                            onClick={() => onClickViewQuestionnaire(row)}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <Modal
              id="questionnaires"
              title={selectedQuestionnaire ? selectedQuestionnaire.title : ""}
              show={showQuestionnaireModal}
              onClose={closeQuestionnaire}
              className={styles.questionnaireModal}
              hideModalHeader={hideQuestionnaireModalHeader}
              key={`questionnaire-modal-${selectedQuestionnaire?.questionnaireId}-${selectedQuestionnaire?.contId}`}
            >
              <Questionnaire
                selectedQuestionnaire={selectedQuestionnaire as QuestionnaireDataParams}
                closeQuestionnaire={closeQuestionnaire}
                mutateQuestionnaires={mutateQuestionnaires}
                isReview={false}
                setHideQuestionnaireModalHeader={setHideQuestionnaireModalHeader}
                key={`questionnaire-${selectedQuestionnaire?.questionnaireId}-${selectedQuestionnaire?.contId}`}
              />
            </Modal>
          </div>
        )}

        {activeTab === "documents" && (
          <div className={styles.section}>
            <div className={styles.filterButtons}>
              <Button
                id="uploadDocuments"
                style="upload"
                mobileText="New"
                className={styles.uploadButton}
                onClick={openUploadDocumentsModal}
              >
                Upload Documents
              </Button>
              <Filter
                id="filterDocuments"
                text="Filter documents"
                filterOptions={documentsFilterOptions}
                isVisible={isDocFilterDropdownVisible}
                onCheckboxChange={handleDocFilterChange}
                setVisible={setDocFilterDropdownVisible}
                className={styles.filter}
                mobileText="Filter"
              />
              <SingleFilterDropDown
                id="sortDocuments"
                label="Sort documents"
                optionItems={documentsSortOptions}
                onOptionSelected={handleDocSortChange}
                dropDownDirectionClass={styles.dropDownDirectionClass}
                mobileText="Sort"
              />
              <SearchInput
                id="searchDocuments"
                placeholder="Try 'work passport'"
                onChange={onDocSearch}
                className={styles.searchBar}
              />
            </div>

            <div className={styles.documentContainerWrapper}>
              <div className={styles.tableScrollContainer}>
                <table className={classNames(styles.clientTable, styles.documentsTable)}>
                  <thead>
                    <tr>
                      <th>Document Name</th>
                      <th>Uploaded By</th>
                      <th>Uploaded On</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {documentData &&
                      documentData.map((doc: any, index: number) => (
                        <DashboardDocumentRow
                          key={doc.docId}
                          index={index}
                          docDesc={doc.docDesc}
                          uploadedBy={doc.uploadedBy || "N/A"}
                          created={format(new Date(doc.created), "yyyy/MM/dd")}
                          onRowClick={() => onClickDocument(doc)}
                          onDownload={() => handleDownload(doc)}
                        />
                      ))}
                  </tbody>
                </table>
                {activeTab === "documents" && (
                  <div ref={loader} style={{ height: "1px", backgroundColor: "transparent" }} />
                )}
                {isValidating && documentData && size > 1 && <div>Loading more...</div>}
                {size === 1 && isValidating && (
                  <LoadingContainer loadingLabel="Loading documents..." />
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        id="documentsUpload"
        title={`Upload Documents to File: ${location.state?.fileNo || applicationInfo?.fileNo}`}
        show={showUploadDocumentsModal}
        onClose={handleCloseDocumentsModal}
        headerClass={styles.modalHeader}
        className={styles.uploadDocumentModal}
        hideExitButton={false}
      >
        <div className={styles.modalContent}>
          {showUploadDocumentsModalElements && (
            <div className={styles.uploadDocumentContainer}>
              <FileUpload
                id={UPLOAD_FIELD_ID}
                label="+ Upload Documents"
                addDocuments={addDocuments}
                locked={isUploading}
                style={FileUploadStyle.BlueBubble}
                register={register}
              />
            </div>
          )}
          <div className={styles.uploadTextContainer}>
            {uploadQueue.success && !errors.documentPageFileUpload && (
              <p className={styles.uploadCompleteText}>Document Upload Complete!</p>
            )}
            {errors.documentPageFileUpload && (
              <p className={styles.error}>{errors.documentPageFileUpload.message}</p>
            )}
            {uploadMessages && uploadMessages.length > 0 && (
              <div className={styles.uploadMessagesContainer}>
                <p className={styles.error}>
                  Parts of your zipped folder failed to upload! Some file types are not supported.
                </p>
                {uploadMessages.map((message, idx) => (
                  <p key={`uploadMessage_${idx}`} className={styles.error}>
                    {message}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        id="documentPreview"
        title={selectedDocument?.docDesc || "Loading document..."}
        show={showDocumentModal}
        onClose={() => {
          setShowDocumentModal(false);
          setSelectedDocument(null);
        }}
        className={styles.documentModal}
        hideModalHeader={false}
      >
        <div className={!isLoadingPreview ? styles.modalContent : styles.loadingContainer}>
          {isLoadingPreview ? (
            <LoadingContainer loadingLabel="Loading document preview..." />
          ) : (
            image
          )}
        </div>
        <div className={styles.modalFooter}>
          {!isLoadingPreview && (
            <Button
              id="downloadDocument"
              onClick={() => handleDownload(selectedDocument)}
              disabled={!smbDocument || isDownloading}
              style="gradient"
              className={styles.downloadButton}
            >
              Download Document
            </Button>
          )}
        </div>
      </Modal>
    </Layout>
  );
};
