import classNames from "classnames";
import ArrowSide from "components/icons/arrowSide";
import React, { FC, useRef, useState } from "react";
import style from "./datePicker.module.scss";

interface IndividualComponentState {
  animate?: (source: HTMLDivElement) => Animation;
  animationCleanup?: () => void;
  classNames?: string;
}

interface ComponentState {
  currentView: View;
  year: IndividualComponentState;
  month: IndividualComponentState;
  day: IndividualComponentState;
  bonusComponent?: JSX.Element;
}

interface ButtonPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ANIMATION_DURATION_MS = 500;
const ANIM_OPTS = { duration: ANIMATION_DURATION_MS, iterations: 1 };
const DEFAULT_FOOTER_HEIGHT = 57;

const animSlide = (direction: number, destination: "in" | "out"): Keyframe[] | PropertyIndexedKeyframes => [
  {
    left: destination === "in" ? `${direction * 100}%` : "0",
  },
  {
    left: destination === "in" ? "0" : `${-direction * 100}%`,
  },
];

const animScaleIn = (srcTop: number, srcLeft: number, srcScale: number): Keyframe[] | PropertyIndexedKeyframes => [
  {
    top: `${srcTop}px`,
    left: `${srcLeft}px`,
    transform: `scale(${srcScale})`, // translateX(${-srcLeft}px) translateY(${-srcTop}px)`,
    transformOrigin: `top left`,
    opacity: 0,
  },
  {
    top: 0,
    left: 0,
    transform: `scale(1)`,
    transformOrigin: `top left`,
    opacity: 1,
  },
];

const animScaleAway = (dstTop: number, dstLeft: number, dstScale: number): Keyframe[] | PropertyIndexedKeyframes => [
  {
    top: 0,
    left: 0,
    transform: `scale(1)`,
    transformOrigin: `top left`,
    opacity: 1,
  },
  {
    top: `${dstTop}px`,
    left: `${dstLeft}px`,
    transform: `scale(${dstScale})`, // translateX(${-dstLeft}px) translateY(${-dstTop}px)`,
    transformOrigin: `top left`,
    opacity: 0,
  },
];

enum View {
  Day,
  Month,
  Year,
}

const YearTable = ({
  currentYear,
  chooseYear,
  currentViewDate,
  className,
  animationEndCleanup,
  animate,
  style: customStyles,
  addSizeRecord,
}: {
  currentYear: number;
  chooseYear: (from: View, year: number, source: DOMRect) => void;
  currentViewDate: Date;
  className?: string;
  animationEndCleanup?: () => void;
  animate?: (source: HTMLDivElement) => Animation;
  style?: React.CSSProperties;
  addSizeRecord?: (idx: number, sizes: ButtonPosition) => void;
}) => {
  const mainTableRef = (component: HTMLDivElement) => {
    if (component !== null && animate) {
      const animation = animate(component);
      if (animationEndCleanup) animation.addEventListener("finish", animationEndCleanup, { once: true });
    }
  };

  const getRef = (idx: number) => {
    return (component: HTMLDivElement) => {
      if (!component) return;
      const rect = component.getBoundingClientRect();
      addSizeRecord &&
        addSizeRecord(idx, {
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
        });
    };
  };

  const viewFirstYear = Math.trunc(currentViewDate.getFullYear() / 10) * 10 - 1;

  const greyedIdxs = [0, 11];

  return (
    <div
      className={classNames(style.mainTable, style.keepAnimation, className)}
      ref={mainTableRef}
      style={customStyles}
    >
      {[...Array(3).keys()].map((rowIdx) => (
        <div key={`day_row_${rowIdx}_${viewFirstYear}`} className={classNames(style.row)}>
          {[...Array(4).keys()].map((cellIdx) => (
            <div
              key={`day_cell_${cellIdx}_${rowIdx}_${viewFirstYear}`}
              className={classNames(
                style.cell,
                viewFirstYear + rowIdx * 4 + cellIdx === currentYear && style.currentCell,
                greyedIdxs.includes(rowIdx * 4 + cellIdx) && style.greyedCell2,
                style.monthYearCell
              )}
              onClick={(e) =>
                !greyedIdxs.includes(rowIdx * 4 + cellIdx)
                  ? chooseYear(View.Year, viewFirstYear + rowIdx * 4 + cellIdx, e.currentTarget.getBoundingClientRect())
                  : void 0
              }
              ref={getRef(rowIdx * 4 + cellIdx)}
            >
              {viewFirstYear + rowIdx * 4 + cellIdx}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const MonthTable = ({
  currentViewYear,
  currentMonth,
  currentViewDate,
  currentYear,
  chooseMonth,
  className,
  animationEndCleanup,
  animate,
  style: customStyles,
  addSizeRecord,
}: {
  currentViewYear: number;
  currentMonth: number;
  currentViewDate: Date;
  currentYear: boolean;
  chooseMonth: (from: View, year: number, source: DOMRect) => void;
  className?: string;
  animationEndCleanup?: () => void;
  animate?: (source: HTMLDivElement) => Animation;
  style?: React.CSSProperties;
  addSizeRecord?: (idx: number, sizes: ButtonPosition) => void;
}) => {
  const mainTableRef = (component: HTMLDivElement) => {
    if (component !== null && animate) {
      const animation = animate(component);
      if (animationEndCleanup) animation.addEventListener("finish", animationEndCleanup, { once: true });
    }
  };

  const getRef = (idx: number) => {
    return (component: HTMLDivElement) => {
      if (!component) return;
      const rect = component.getBoundingClientRect();
      addSizeRecord &&
        addSizeRecord(idx, {
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
        });
    };
  };

  return (
    <div
      className={classNames(style.mainTable, style.keepAnimation, className)}
      ref={mainTableRef}
      style={customStyles}
    >
      {[...Array(3).keys()].map((rowIdx) => (
        <div key={`month_row_${rowIdx}_${currentViewYear}`} className={classNames(style.row)}>
          {[...Array(4).keys()].map((cellIdx) => (
            <div
              key={`month_cell_${cellIdx}_${rowIdx}`}
              className={classNames(
                style.cell,
                rowIdx * 4 + cellIdx === currentMonth && currentYear && style.currentCell,
                style.monthYearCell
              )}
              onClick={(e) => chooseMonth(View.Month, rowIdx * 4 + cellIdx, e.currentTarget.getBoundingClientRect())}
              ref={getRef(rowIdx * 4 + cellIdx)}
            >
              {MONTHS[rowIdx * 4 + cellIdx].slice(0, 3)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const DayTable = ({
  currentDay,
  chooseDay,
  currentMonth,
  currentViewDate,
  className,
  animationEndCleanup,
  animate,
  style: customStyles,
  addSizeRecord,
}: {
  currentDay: number;
  chooseDay: (day: number) => void;
  currentViewDate: Date;
  currentMonth: boolean;
  className?: string;
  animationEndCleanup?: () => void;
  animate?: (source: HTMLDivElement) => Animation;
  style?: React.CSSProperties;
  addSizeRecord?: (idx: number, sizes: ButtonPosition) => void;
}) => {
  const day = new Date(currentViewDate.getFullYear(), currentViewDate.getMonth(), 1).getDay();
  const startIdx = -day + 1;

  const monthDays = [...Array(42).keys()].map((idx) => [ //42 for 6 rows for August 2020
    new Date(currentViewDate.getFullYear(), currentViewDate.getMonth(), idx + startIdx).getMonth(),
    new Date(currentViewDate.getFullYear(), currentViewDate.getMonth(), idx + startIdx).getDate(),
  ]);

  const mainTableRef = (component: HTMLDivElement) => {
    if (component !== null && animate) {
      const animation = animate(component);
      if (animationEndCleanup) animation.addEventListener("finish", animationEndCleanup, { once: true });
    }
  };

  const getRef = (idx: number) => {
    return (component: HTMLDivElement) => {
      if (!component) return;
      const rect = component.getBoundingClientRect();
      addSizeRecord &&
        addSizeRecord(idx, {
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
        });
    };
  };

  return (
    <div
      className={classNames(style.mainTable, style.keepAnimation, className)}
      ref={mainTableRef}
      style={customStyles}
      key={`day_table_${currentViewDate.getMonth()}`}
      id={`day_table_${currentViewDate.getMonth()}`}
    >
      <div className={classNames(style.row, style.headerRow)}>
        {DAYS.map((day) => (
          <div key={`week_header_${day}`} className={classNames(style.headerCell)}>
            {day}
          </div>
        ))}
      </div>
      {[...Array(6).keys()].map((rowIdx) => ( //6 rows for August 2020
        <div key={`row_idx_${rowIdx}`} className={classNames(style.row)}>
          {[...Array(7).keys()].map((cellIdx) => (
            <div
              key={`cell_idx_${cellIdx}_${rowIdx}`}
              className={classNames(
                style.cell,
                monthDays[rowIdx * 7 + cellIdx][1] === currentDay && currentMonth && monthDays[rowIdx * 7 + cellIdx][0] === currentViewDate.getMonth() && style.currentCell,
                monthDays[rowIdx * 7 + cellIdx][0] !== currentViewDate.getMonth() && style.greyedCell,
              )}
              onClick={() =>
                monthDays[rowIdx * 7 + cellIdx][0] === currentViewDate.getMonth() &&
                chooseDay(monthDays[rowIdx * 7 + cellIdx][1])
              }
              ref={getRef(rowIdx * 7 + cellIdx)}
            >
              {monthDays[rowIdx * 7 + cellIdx][1]}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

interface DatePickerProps {
  value?: Date;
  close: (selected?: Date) => void;
  maxDate?: Date;
  minDate?: Date;
}

const DatePicker: FC<DatePickerProps> = ({ value, close, maxDate, minDate }) => {
  const currentDate = new Date();
  const formatter = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  const formattedDate = formatter.format(currentDate);
  const [transitioning, setTransitioning] = useState(0);
  const [componentState, setComponentState] = useState<ComponentState>({
    currentView: View.Day,
    year: { animate: undefined, animationCleanup: () => void 0, classNames: classNames(style.invisible) },
    month: { animate: undefined, animationCleanup: () => void 0, classNames: classNames(style.invisible) },
    day: { animate: undefined, animationCleanup: () => void 0, classNames: "" },
    bonusComponent: undefined,
  });
  const [currentViewDate, setCurrentViewDate] = useState(value ?? currentDate);
  const buttonPositions = useRef<{
    day: {
      [idx: number]: ButtonPosition;
    };
    month: {
      [idx: number]: ButtonPosition;
    };
    year: {
      [idx: number]: ButtonPosition;
    };
  }>({
    day: {},
    month: {},
    year: {},
  });
  const animationWrapperRect = useRef<DOMRect>();
  const footerHeight = useRef<number>();

  const getPos = (currentViewDate: Date, view: View) => {
    if (view === View.Month) {
      const viewMonth = currentViewDate.getMonth();
      return buttonPositions.current.month[viewMonth];
    } else if (view === View.Year) {
      const viewFirstYear = Math.trunc(currentViewDate.getFullYear() / 10) * 10 - 1;
      return buttonPositions.current.month[currentViewDate.getFullYear() - viewFirstYear];
    } else {
      return { left: 0, top: 0, width: 0, height: 0 };
    }
  };

  const pickYear = (from: View, year: number, source: DOMRect) => {
    if (maxDate && year > maxDate.getFullYear()) return;
    if (minDate && year < minDate.getFullYear()) return;
    setCurrentViewDate((viewDate) => {
      const newDate = new Date(viewDate);
      newDate.setFullYear(year);
      return newDate;
    });
    transitionView(from, View.Month, source, currentViewDate);
  };

  const pickMonth = (from: View, month: number, source: DOMRect) => {
    if (maxDate && new Date(currentViewDate.getFullYear(), month, 1) > maxDate) return;
    if (minDate && new Date(currentViewDate.getFullYear(), month, 1) < minDate) return;
    setCurrentViewDate((viewDate) => {
      const newDate = new Date(viewDate);
      newDate.setMonth(month);
      return newDate;
    });
    transitionView(from, View.Day, source, currentViewDate);
  };

  const pickDay = (day: number) => {
    if (maxDate && new Date(currentViewDate.getFullYear(), currentViewDate.getMonth(), day) > maxDate) return;
    if (minDate && new Date(currentViewDate.getFullYear(), currentViewDate.getMonth(), day) < minDate) return;
    const newDate = new Date(currentViewDate);
    newDate.setDate(day);
    close(newDate);
  };

  const transitionView = (from: View, to: View, source: DOMRect, currentViewDate: Date) => {
    if (transitioning !== 0) return;

    const animationCleanup = () => {
      setComponentState({
        currentView: to,
        year: { classNames: classNames(to === View.Year ? "" : style.invisible) },
        month: { classNames: classNames(to === View.Month ? "" : style.invisible) },
        day: { classNames: classNames(to === View.Day ? "" : style.invisible) },
        bonusComponent: undefined,
      });
      setTransitioning(0);
    };

    switch (from) {
      case View.Day: {
        if (to !== View.Month) return;

        const buttonPos = getPos(currentViewDate, View.Month);
        buttonPos.top =
          buttonPos.top -
          animationWrapperRect.current!.top -
          (Math.trunc(currentViewDate.getMonth() / 4 + 1) * (footerHeight.current ?? DEFAULT_FOOTER_HEIGHT)) / 4;
        buttonPos.left = buttonPos.left - animationWrapperRect.current!.left;
        const calcedScale = buttonPos.width / animationWrapperRect.current!.width;

        setComponentState({
          currentView: to,
          year: { classNames: classNames(style.invisible) },
          month: {
            classNames: "",
            animationCleanup,
            animate: (component) =>
              component.animate(
                animScaleIn(-buttonPos.top / calcedScale, -buttonPos.left / calcedScale, 1 / calcedScale),
                ANIM_OPTS,
              ),
          },
          day: {
            classNames: "",
            animate: (component) =>
              component.animate(animScaleAway(buttonPos.top, buttonPos.left, calcedScale), ANIM_OPTS),
          },
          bonusComponent: undefined,
        });
        setTransitioning(-100);
        break;
      }

      case View.Month: {
        if (to === View.Year) {
          const buttonPos = getPos(currentViewDate, View.Year);
          buttonPos.top = buttonPos.top - animationWrapperRect.current!.top;
          buttonPos.left = buttonPos.left - animationWrapperRect.current!.left;
          const calcedScale = buttonPos.width / animationWrapperRect.current!.width;

          setComponentState({
            currentView: to,
            year: {
              classNames: "",
              animate: (component) =>
                component.animate(
                  animScaleIn(-buttonPos.top / calcedScale, -buttonPos.left / calcedScale, 1 / calcedScale),
                  ANIM_OPTS,
                ),
            },
            month: {
              classNames: "",
              animationCleanup,
              animate: (component) =>
                component.animate(animScaleAway(buttonPos.top, buttonPos.left, calcedScale), ANIM_OPTS),
            },
            day: {
              classNames: classNames(style.invisible),
            },
            bonusComponent: undefined,
          });
          setTransitioning(-100);
        } else if (to === View.Day) {
          const buttonPos = {
            top:
              source.top -
              animationWrapperRect.current!.top +
              (Math.trunc(currentViewDate.getMonth() / 4 + 1) * (footerHeight.current ?? 57)) / 4,
            left: source.left - animationWrapperRect.current!.left,
          };
          const calcedScale = source.width / animationWrapperRect.current!.width;

          setComponentState({
            currentView: to,
            year: { classNames: classNames(style.invisible) },
            month: {
              classNames: "",
              animationCleanup,
              animate: (component) =>
                component.animate(
                  animScaleAway(-buttonPos.top / calcedScale, -buttonPos.left / calcedScale, 1 / calcedScale),
                  ANIM_OPTS,
                ),
            },
            day: {
              classNames: "",
              animate: (component) =>
                component.animate(animScaleIn(buttonPos.top, buttonPos.left, calcedScale), ANIM_OPTS),
            },
            bonusComponent: undefined,
          });
          setTransitioning(100);
        }
        break;
      }

      case View.Year: {
        if (to === View.Month) {
          const buttonPos = {
            top: source.top - animationWrapperRect.current!.top,
            left: source.left - animationWrapperRect.current!.left,
          };
          const calcedScale = source.width / animationWrapperRect.current!.width;

          setComponentState({
            currentView: to,
            year: {
              classNames: "",
              animate: (component) =>
                component.animate(
                  animScaleAway(-buttonPos.top / calcedScale, -buttonPos.left / calcedScale, 1 / calcedScale),
                  ANIM_OPTS,
                ),
            },
            month: {
              classNames: "",
              animationCleanup,
              animate: (component) =>
                component.animate(animScaleIn(buttonPos.top, buttonPos.left, calcedScale), ANIM_OPTS),
            },
            day: { classNames: classNames(style.invisible) },
            bonusComponent: undefined,
          });
          setTransitioning(100);
          break;
        } else if (to === View.Day) {
          const buttonPos = {
            top: source.top - animationWrapperRect.current!.top,
            left: source.left - animationWrapperRect.current!.left,
          };
          const calcedScale = source.width / animationWrapperRect.current!.width;

          setComponentState({
            currentView: to,
            year: {
              classNames: "",
              animate: (component) =>
                component.animate(
                  animScaleAway(-buttonPos.top / calcedScale, -buttonPos.left / calcedScale, 1 / calcedScale),
                  ANIM_OPTS,
                ),
            },
            month: { classNames: classNames(style.invisible) },
            day: {
              classNames: "",
              animationCleanup,
              animate: (component) =>
                component.animate(animScaleIn(buttonPos.top, buttonPos.left, calcedScale), ANIM_OPTS),
            },
            bonusComponent: undefined,
          });
          setTransitioning(100);
          break;
        }
      }
    }
  };

  const transitionSameTable = (forwards: boolean) => {
    if (transitioning) return;

    const animationCleanup = () => {
      setComponentState((cs) => ({
        currentView: cs.currentView,
        year: { classNames: classNames(cs.currentView === View.Year ? "" : style.invisible) },
        month: { classNames: classNames(cs.currentView === View.Month ? "" : style.invisible) },
        day: { classNames: classNames(cs.currentView === View.Day ? "" : style.invisible) },
        bonusComponent: undefined,
      }));
      setTransitioning(0);
    };

    const direction = forwards ? 1 : -1;

    switch (componentState.currentView) {
      case View.Day:
        {
          const currentViewMonth = currentViewDate.getMonth();
          let newViewMonth = currentViewMonth + direction;
          let newViewYear = currentViewDate.getFullYear();
          if (newViewMonth < 0) {
            newViewMonth = 11;
            newViewYear--;
          } else if (newViewMonth > 11) {
            newViewMonth = 0;
            newViewYear++;
          }

          if (maxDate && new Date(currentViewDate.getFullYear(), newViewMonth, 1) > maxDate) return;
          if (minDate && new Date(currentViewDate.getFullYear(), newViewMonth, 1) < minDate) return;

          const Current = (
            <DayTable
              key={`day_table_${currentViewMonth}`}
              currentViewDate={currentViewDate}
              currentDay={currentDate.getDate()}
              chooseDay={() => void 0}
              currentMonth={
                currentDate.getFullYear() === currentViewDate.getFullYear() &&
                currentDate.getMonth() === currentViewMonth
              }
              animate={(el) => el.animate(animSlide(direction, "out"), ANIM_OPTS)}
            />
          );

          setComponentState((cs) => ({
            ...cs,
            day: {
              animate: (el) => el.animate(animSlide(direction, "in"), ANIM_OPTS),
              classNames: classNames(),
              animationCleanup: animationCleanup,
            },
            bonusComponent: Current,
          }));

          setCurrentViewDate((viewDate) => {
            const newDate = new Date(viewDate);
            newDate.setMonth(newViewMonth);
            newDate.setFullYear(newViewYear);
            return newDate;
          });

          setTransitioning(direction);
        }
        break;

      case View.Month:
        {
          const currentViewYear = currentViewDate.getFullYear();
          const newViewYear = currentViewYear + direction;

          if (maxDate && new Date(newViewYear, 0, 1) > maxDate) return;
          if (minDate && new Date(newViewYear, 0, 1) < minDate) return;

          const Current = (
            <MonthTable
              key={`month_table_${currentViewYear}`}
              currentMonth={currentDate.getMonth()}
              currentViewYear={currentViewYear}
              chooseMonth={() => void 0}
              currentViewDate={currentViewDate}
              currentYear={currentViewYear === currentDate.getFullYear()}
              animate={(el) => el.animate(animSlide(direction, "out"), ANIM_OPTS)}
            />
          );

          setComponentState((cs) => ({
            ...cs,
            month: {
              animate: (el) => el.animate(animSlide(direction, "in"), ANIM_OPTS),
              classNames: classNames(),
              animationCleanup: animationCleanup,
            },
            bonusComponent: Current,
          }));

          setCurrentViewDate((viewDate) => {
            const newDate = new Date(viewDate);
            newDate.setFullYear(newViewYear);
            return newDate;
          });

          setTransitioning(direction);
        }
        break;

      case View.Year:
        {
          const currentViewFirstYear = Math.trunc(currentViewDate.getFullYear() / 10) * 10 - 1;
          const newViewFirstYear = currentViewFirstYear + 10 * direction;

          if (maxDate && new Date(newViewFirstYear, 0, 1) > maxDate) return;
          if (minDate && new Date(newViewFirstYear, 11, 31) < minDate) return;

          const Current = (
            <YearTable
              key={`year_table_${currentViewFirstYear}`}
              currentViewDate={currentViewDate}
              currentYear={currentDate.getFullYear()}
              chooseYear={() => void 0}
              animate={(el) => el.animate(animSlide(direction, "out"), ANIM_OPTS)}
            />
          );

          setComponentState((cs) => ({
            ...cs,
            year: {
              animate: (el) => el.animate(animSlide(direction, "in"), ANIM_OPTS),
              classNames: classNames(),
              animationCleanup: animationCleanup,
            },
            bonusComponent: Current,
          }));

          setCurrentViewDate((viewDate) => {
            const newDate = new Date(viewDate);
            newDate.setFullYear(currentViewDate.getFullYear() + 10 * direction);
            return newDate;
          });

          setTransitioning(direction);
        }
        break;
    }
  };

  const headerText = (() => {
    switch (componentState.currentView) {
      case View.Day: {
        const currentViewMonth = MONTHS[currentViewDate.getMonth()];
        const currentViewYear = currentViewDate.getFullYear();
        return `${currentViewMonth} ${currentViewYear}`;
      }
      case View.Month: {
        const currentViewYear = currentViewDate.getFullYear();
        return `${currentViewYear}`;
      }
      case View.Year: {
        const currentFirstYear = Math.trunc(currentViewDate.getFullYear() / 10) * 10 - 1;
        return `${currentFirstYear + 1}-${currentFirstYear + 10}`;
      }
    }
  })();

  let transitionOut: View | undefined = undefined;
  if (componentState.currentView === View.Day) transitionOut = View.Month;
  else if (componentState.currentView === View.Month) transitionOut = View.Year;
  else if (componentState.currentView === View.Year) transitionOut = View.Day;

  return (
    <div
      className={style.animatedDatePickerWrapper}
      onClick={(e) => {
        e.stopPropagation();
        close();
      }}
    >
      <div className={classNames(style.datePicker)} onClick={(e) => e.stopPropagation()}>
        <div className={classNames(style.topBar)}>
          <div className={classNames(style.arrowLeft)} onClick={() => transitionSameTable(false)}>
            <ArrowSide />
          </div>
          <div
            className={classNames(style.header)}
            onClick={(e) => {
              if (transitionOut !== undefined)
                transitionView(
                  componentState.currentView,
                  transitionOut,
                  e.currentTarget.getBoundingClientRect(),
                  currentViewDate,
                );
            }}
          >
            {headerText}
          </div>
          <div className={classNames(style.arrowRight)} onClick={() => transitionSameTable(true)}>
            <ArrowSide />
          </div>
        </div>
        <div
          id="datePickerAnimationWrapper"
          className={style.animationWrapper}
          ref={(comp) => (animationWrapperRect.current = comp?.getBoundingClientRect())}
        >
          <DayTable
            key={`day_table_${currentViewDate.getMonth()}_${currentViewDate.getFullYear()}`}
            className={componentState.day.classNames}
            animationEndCleanup={componentState.day.animationCleanup}
            chooseDay={pickDay}
            currentDay={currentDate.getDate()}
            currentViewDate={currentViewDate}
            currentMonth={
              currentDate.getFullYear() === currentViewDate.getFullYear() &&
              currentDate.getMonth() === currentViewDate.getMonth()
            }
            animate={componentState.day.animate}
            addSizeRecord={(idx, sizes) => (buttonPositions.current.day[idx] = sizes)}
          />

          <MonthTable
            key={`month_table_${currentViewDate.getFullYear()}`}
            className={componentState.month.classNames}
            animationEndCleanup={componentState.month.animationCleanup}
            currentMonth={currentDate.getMonth()}
            currentViewYear={currentViewDate.getFullYear()}
            chooseMonth={pickMonth}
            currentViewDate={currentViewDate}
            currentYear={currentViewDate.getFullYear() === currentDate.getFullYear()}
            animate={componentState.month.animate}
            addSizeRecord={(idx, sizes) => (buttonPositions.current.month[idx] = sizes)}
          />

          <YearTable
            key={`year_table_${currentViewDate.getFullYear()}`}
            className={componentState.year.classNames}
            animationEndCleanup={componentState.year.animationCleanup}
            chooseYear={pickYear}
            currentYear={currentDate.getFullYear()}
            currentViewDate={currentViewDate}
            animate={componentState.year.animate}
            addSizeRecord={(idx, sizes) => (buttonPositions.current.year[idx] = sizes)}
          />
          {componentState.bonusComponent}
        </div>
        <div
          id="todaysDateFooter"
          className={classNames(style.currentDate, componentState.currentView === View.Day && style.hide)}
          ref={(component) =>
            component?.getBoundingClientRect().height === 0 || component?.getBoundingClientRect().height === undefined
              ? void 0
              : (footerHeight.current = component?.getBoundingClientRect().height)
          }
          onClick={() => close(new Date())}
        >
          Today: {formattedDate}
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
