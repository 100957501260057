import React from 'react';
import style from "./dashboardAppRow.module.scss";
import classNames from "classnames";
import { IntStatus, IntStatusLabels, getStatusClass, getStatusLabel, parseHTML } from 'utils/commonFunctions';

interface IDashboardAppRow {
  index: number;
  intStatus: IntStatus;
  progress: number;
  header: string;
  onClick?: () => void;
}

export const DashboardAppRow = ({
  index,
  intStatus,
  progress,
  header,
  onClick = () => undefined,
}: IDashboardAppRow) => {
  const space = '\u00a0';
  const statusClass = getStatusClass(intStatus, style);
  const status = getStatusLabel(intStatus);
  let mobileStatus = ' ';

  if (intStatus === IntStatus.Active) {
    mobileStatus = String(status);
    mobileStatus = `${space}(${progress}%)`;
  }

  return (
    <>
      { index != 0 && <tr className={style.barRow}>
        <td colSpan={4}/>
      </tr>}
      <tr id={"DashboardAppRow"} className={style.selectableRow} onClick={onClick}>
        <td className={style.textTitle}>{parseHTML(header)}</td>
        <td className={style.headers}>
          <div className={style.progressContainer}>
            <div className={style.progressBar}>
              <div 
                className={style.progressFill} 
                style={{ width: `${progress}%` }} 
              ></div>
            </div>
            <span className={style.progressText}>{`${progress}% complete`}</span>
          </div>
        </td>
        <td>
          <div className={classNames(statusClass, style.bubble)}>
            <span id={"DashboardAppRow-status"}className={style.desktopStatus}>{status}</span>
            <span id={"DashboardAppRow-status"}className={style.mobileStatus}>{mobileStatus}</span>
          </div>
        </td>
      </tr>
    </>
  );
};
