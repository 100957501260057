import BackArrowIcon from 'components/icons/backArrowIcon';
import style from "./backArrow.module.scss";
import { useHistory } from 'react-router-dom';

interface BackArrowProps {
  to?: string;        // Optional explicit path to navigate to
  onBack?: () => void; // Optional callback for custom navigation logic
}

export const BackArrow = ({ to, onBack }: BackArrowProps) => {
  const history = useHistory();

  const handleClick = () => {
    if (onBack) {
      onBack();
    } else if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  };

  return (
    <div className={style.backArrow} onClick={handleClick}>
      <BackArrowIcon/>
    </div>
  );
};