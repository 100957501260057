import { useState } from 'react';
import { useForm } from 'react-hook-form';
import style from './newApplicationForm.module.scss';
import { Button } from 'components/UI/button/button';
import DropDownSelect from 'components/UI/dropDownSelect/dropDownSelect';
import DatePicker from 'components/UI/DatePicker/datePicker';
import FileUpload, { DocumentAdditionParams, DocumentDeletionParams } from 'components/UI/FileUpload/fileUpload';
import { Input } from "components/UI/input/input";
import { DropdownOptions, useGetCountries } from 'actions/locationsActions';
import { fileToBase64, parseDateString, isEmptyDropDownValue, getFieldDocumentNames, parseFileExtensions } from 'utils/commonFunctions';
import { DocumentName, DocumentNames } from 'actions/documentActions';
import { validEmailRegex, validPhoneNumberRegex, validZipCodePostalCodeRegex } from 'utils/regexValidations';
import { AllowedExtensions } from 'utils/imageFunctions';
import { MultilineInput } from 'components/UI/multilineInput/multilineInput';
import { getUserEmailCheck } from 'actions/userActions';
import { isValid, parse, isFuture, subYears } from 'date-fns';

interface ApplicationFormProps {
  onSubmitForm: (values: ApplicationFormFields) => void
}

export interface ApplicationFormFields {
  textRequestDetails: string;
  fullName: string;
  lastName: string;
  firstName: string;
  birthday: string;
  nationality: string;
  passportNumber: string;
  title: string;
  streetName: string;
  unitNumber: string;
  town: string;
  province: string;
  postal: string;
  country: string;
  email: string;
  phone: string;
  department?: string;
  applicationType?: string;
  comments?: string;
  fileUploads?: base64Uploads;
  caseAnalyst?: string;
  assignedLawyer?: string;
  matterType?: string;
}

const departmentOptions = [
  { value: 'hr', label: 'Human Resources' },
  { value: 'it', label: 'Information Technology' },
  { value: 'finance', label: 'Finance' },
];

const applicationTypeOptions = [
  { value: 'full-time', label: 'Full-Time' },
  { value: 'part-time', label: 'Part-Time' },
  { value: 'intern', label: 'Internship' },
];

const titleOptions = [
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Mx.', label: 'Mx.' },
  { value: 'Dr.', label: 'Dr.' }
];

export interface FileUploads {
  passportUpload?: File[];
  postSecondaryUpload?: File[];
  resumeUpload?: File[];
  canadianUpload?: File[];
  marriageUpload?: File[];
  familyPassportUpload?: File[];
  familyBirthCertUpload?: File[];
}

export type base64Uploads = {
  passportUpload?: string[];
  postSecondaryUpload?: string[];
  resumeUpload?: string[];
  canadianUpload?: string[];
  marriageUpload?: string[];
  familyPassportUpload?: string[];
  familyBirthCertUpload?: string[];
};

export const NewApplication = ({ onSubmitForm }: ApplicationFormProps) => {
  const { register, handleSubmit, formState: { errors }, setError, clearErrors, setValue, getValues, trigger } = useForm<ApplicationFormFields>(); 
  const [uploads, setUploads] = useState<FileUploads>({});
  const [documentNames, setDocumentNames] = useState<DocumentNames>({ localDocuments: [], uploadedDocuments: [] } as DocumentNames);
  const [provinceOptions, setProvinceOptions] = useState<DropdownOptions[]>([]);

  const {
    data: countryOptions,
    error: errorCountryOptions,
    isLoading: countryOptionsIsLoading,
  } = useGetCountries();

  const validateBirthday = () => {
    const value = getValues("birthday");
    
    // Check if value exists
    if (!value) {
      setError("birthday", { 
        type: "required", 
        message: "Date of Birth is required" 
      });
      return;
    }

    // Try to parse the date
    const parsedDate = parse(value, 'yyyy-M-d', new Date());

    // Check if it's a valid date
    if (!isValid(parsedDate)) {
      setError("birthday", { 
        type: "invalid", 
        message: "Please enter a valid date" 
      });
      return;
    }

    // Check if date is in the future
    if (isFuture(parsedDate)) {
      setError("birthday", { 
        type: "future", 
        message: "Date of Birth cannot be in the future" 
      });
      return;
    }

    // Check if date is too far in the past (e.g., over 120 years)
    const minDate = subYears(new Date(), 120);
    if (parsedDate < minDate) {
      setError("birthday", { 
        type: "past", 
        message: "Please enter a Date of Birth in the last 120 years" 
      });
      return;
    }

    // If we get here, the date is valid
    clearErrors("birthday");
  };

  const customValidation = () => { // Leaving in case extra cases need to be added
    validateBirthday();
  };
  const onSubmit = async (values: ApplicationFormFields) => {
    const uploadsBase64Promises = Object.entries(uploads).flatMap(([uploadType, files]) => {
      return files?.map((file: File) => 
        fileToBase64(file).then(base64 => {
          const extension = file.name.split('.').pop();  
          const base64RemoveExtension = base64.split(',')[1];
          return { 
            uploadType, 
            base64: base64RemoveExtension, 
            docExt: extension, 
            docName: file.name 
          };
        })
      ) || [];
    });
  
    try {
      await handleEmailBlur();
      const base64Results = await Promise.all(uploadsBase64Promises);
  
      // Group the Base64 objects by their uploadType
      const groupedBase64 = base64Results.reduce((acc, { uploadType, base64, docExt, docName }) => {
        if (!acc[uploadType]) {
          acc[uploadType] = [];
        }
        acc[uploadType].push({ base64, docExt, docName });
        return acc;
      }, {} as Record<string, Array<{ base64: string, docExt: string, docName: string }>>);
  
      onSubmitForm({ ...values, fileUploads: groupedBase64 });
    } catch (e) {
      console.error(e);
    }
  };
  
  const handleFormValueChange = (name: string, value: string) => {
    if (name === "birthday") {
      clearErrors("birthday");
    }
    setValue(name as keyof ApplicationFormFields, value);
    trigger(name as keyof ApplicationFormFields);
  };

  const handleCountryDropDownChange = (value: string) => {
    handleFormValueChange("country", value);

    const selectedCountry = countryOptions?.find(
      (country) => country.value === value
    );
    const hasProvinces = selectedCountry?.hasProvinceOrState || false;

    if (hasProvinces) {
      setProvinceOptions(selectedCountry?.provinceOrStates || []);

    } else {
      setProvinceOptions([]);
      setValue("province", "");
    }
  };

  const handleCalendarChange = (name: string, value: string) => {
    if (value) {
      handleFormValueChange(name as keyof ApplicationFormFields, value);
    }
  };

  const addDocuments = ({ files, fieldId }: DocumentAdditionParams) => {
    clearErrors(fieldId as keyof ApplicationFormFields);

    const { supportedFiles, error } = parseFileExtensions(files, AllowedExtensions);
    if (error) {
      setError(fieldId as keyof ApplicationFormFields, error);
    }
    if (supportedFiles.length === 0) return;
    const supportedDocNameSet = new Set(supportedFiles.map(file => file.name));

    setUploads(prevUploads => {
      const key = fieldId as keyof FileUploads;
      const filteredUploads = Object.entries(prevUploads).reduce<FileUploads>((acc, [key, existingFiles]: [string, File[]]) => {
        return {
          ...acc,
          [key]: existingFiles.filter(file => !supportedDocNameSet.has(file.name)),
        };
      }, {} as FileUploads);
      
      const currentFiles = filteredUploads[key] || [];

      const updatedUploads = { 
        ...filteredUploads, 
        [key]: [...currentFiles, ...supportedFiles]
      };
      return updatedUploads;
    });

    setDocumentNames(prevDocumentNames => {
      const newDocuments = supportedFiles.map(file => {
        return {
          name: file.name,
          fieldId: fieldId,
          permanent: false,
        } as DocumentName;
      });
      const filteredDocumentNames = {
        ...prevDocumentNames,
        localDocuments: prevDocumentNames.localDocuments.filter(doc => !supportedDocNameSet.has(doc.name)),
      };
      const updatedDocumentNames = {
        ...filteredDocumentNames,
        localDocuments: [...filteredDocumentNames.localDocuments, ...newDocuments],
      };
      
      return updatedDocumentNames;
    });
  };

  const deleteDocument = ({ name, fieldId }: DocumentDeletionParams) => {
    clearErrors(fieldId as keyof ApplicationFormFields);

    setUploads(prevUploads => {
      const key = fieldId as keyof FileUploads;
      return {
        ...prevUploads,
        [key]: prevUploads[key]?.filter(file => file.name !== name),
      };
    });
    setDocumentNames(prevDocumentNames => {
      return {
        ...prevDocumentNames,
        localDocuments: prevDocumentNames.localDocuments.filter(doc => doc.name !== name || doc.fieldId !== fieldId),
      } as DocumentNames;
    });
  };

  const requestDetailsValidationSchema = {
    required: "Details about the request are required",
    maxLength: {
      value: 1000,
      message: "Character Limit: 1,000 characters"
    }
  };

  const phoneValidationSchema = { 
    required: "Telephone is required",
    minLength: {
      value: 10,
      message: "Telephone must be at least 10 characters long",
    },
    pattern: {
      value: validPhoneNumberRegex,
      message: "Check Telephone format",
    },
  };

  const emailValidationSchema = {
    required: "Email is required",
    pattern: {
      value: validEmailRegex,
      message: "Entered value does not match email format",
    }
  };

  const postalZipCodeValidationSchema = { 
    pattern: {
      value: validZipCodePostalCodeRegex,
      message: "invalid format",
    },
  };

  const commonProvinceProps = {
    id: "province",
    validationSchema: { required: "State/Province is required" },
    labelClass: style.labels,
    placeholder: "State/Province",
    register: register,
    errors: errors,
  };
 
  const handleEmailBlur = async () => {
    const email = getValues("email");
    if (email && validEmailRegex.test(email)) {
      try {
        await getUserEmailCheck({ email });
        clearErrors("email");
      } catch (error) {
        setError("email", {
          type: "manual",
          message: "This email is already registered. Please use a different email."
        });
        throw new Error("This email is already registered. Please use a different email.");
      }
    }
  };

  return (
    <div className={style.formContent}>
      <form onSubmit={handleSubmit(onSubmit)} className={style.applicationForm}>

        <div className={style.formSection}>
          <div className={style.title}>
            <h4><span>1</span> What Can We Help You With?</h4>
          </div>

          <div className={style.row}>
            <MultilineInput
              id="textRequestDetails"
              label={""}
              placeholder="Please provide details on your request"
              value={getValues("textRequestDetails")}
              onChange={(value) => handleFormValueChange("textRequestDetails", value)}
              rows={6}
              isReview={false}
              isComments={false}
              register={register}
              errors={errors}
              validationSchema={requestDetailsValidationSchema}
            />
          </div>
        </div>

        <div className={style.formSection}>
          <div className={style.title}>
            <h4><span>2</span> Applicant Details</h4>
          </div>

          <div className={style.row}>
            <div className={style.rowColumnOne}>
              <DropDownSelect 
                id='title'
                options={titleOptions}
                onChange={(value) => handleFormValueChange('title', value)} 
                placeholder='Title'
                title="Title"
                register={register}
                errors={errors}
                isRequiredMessage="Please select a title"
              />
            </div>
            <div className={style.rowColumnTwo}>
              <Input
                id="firstName"
                label="Given Name(s) - as stated on passport"
                register={register} 
                value={getValues("firstName")}
                onChange={(value) => handleFormValueChange("firstName", value)}
                placeholder="Given Name(s)" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
                validationSchema={{ required: "Given Name(s) is required" }}
              />
            </div>
            <div className={style.rowColumnTwo}>
              <Input
                id="lastName"
                label="Last Name - as stated on passport"
                register={register} 
                value={getValues("lastName")}
                onChange={(value) => handleFormValueChange("lastName", value)}
                placeholder="Last Name" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
                validationSchema={{ required: "Last Name is required" }}
              />
            </div>
          </div>
        
          <div className={style.row}>
            <div className={style.rowColumnOne}>
              <DatePicker
                id="birthday"
                label="Date of Birth"
                className={style.inputField} 
                register={register}
                value={parseDateString(getValues("birthday"))}
                onChange={(value) => handleCalendarChange("birthday", value)}
                errors={errors}
                maxDate={new Date()}
                minDate={new Date(new Date().setFullYear(new Date().getFullYear() -100))}
                placeholder="YYYY-MM-DD"
              />
            </div>
            <div className={style.rowColumnTwo}>
              <DropDownSelect
                id={'nationality'}
                options={countryOptions || []}
                onChange={(value) => handleFormValueChange('nationality', value)} 
                register={register}
                errors={errors}
                placeholder='-- Please Select --'
                title="Nationality"   
                isRequiredMessage="Please select a nationality"
              />
            </div>
            <div className={style.rowColumnTwo}>
              <Input
                id="passportNumber"
                label="Passport Number"
                register={register} 
                value={getValues("passportNumber")}
                onChange={(value) => handleFormValueChange("passportNumber", value)}
                placeholder="Passport Number" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
                validationSchema={{ required: "Passport Number is required" }}
              />
            </div>
          </div>
        </div>

        <div className={style.formSection}>
          <div className={style.title}>
            <h4><span>3</span> Applicant Contact Details</h4>
          </div>
          <div className={style.row}>
            <div className={style.rowColumnTwo}>
              <Input
                id='streetName'
                label="Street Address"
                register={register} 
                value={getValues("streetName")}
                onChange={(value) => handleFormValueChange("streetName", value)}
                placeholder="Street Address" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
                validationSchema={{ required: "Street Address is required" }}
              />
            </div>
            <div className={style.rowColumnTwo}>
              <Input 
                id='unitNumber'
                label="Unit/Suite Number"
                register={register} 
                value={getValues("unitNumber")}
                onChange={(value) => handleFormValueChange("unitNumber", value)}
                placeholder="Unit/Suite Number" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
              />
            </div>
          </div>

          <div className={style.row}>
            <div className={style.rowColumnTwo}>
              <Input
                id='town'
                label="Town/City"
                register={register} 
                value={getValues("town")}
                onChange={(value) => handleFormValueChange("town", value)}
                placeholder="Placeholder" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
                validationSchema={{ required: "Town/City is required" }}
              />
            </div>
            <div className={style.rowColumnTwo}>
              <DropDownSelect 
                id={'country'}
                options={countryOptions ? countryOptions : []}
                onChange={handleCountryDropDownChange} 
                register={register}
                errors={errors}
                placeholder='Country'
                title="Country"   
                isRequiredMessage='Country is required'
              />
            </div>
          </div>

          <div className={style.row}>
            <div className={style.rowColumnTwo}>
              <Input
                id='postal'
                label="Zip/Postal"
                register={register} 
                value={getValues("postal")}
                onChange={(value) => handleFormValueChange("postal", value)}
                placeholder="Placeholder" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
                validationSchema={postalZipCodeValidationSchema}
              />
            </div>
            <div className={style.rowColumnTwo}>
              {provinceOptions && provinceOptions.length > 0 ? 
                <DropDownSelect
                  title="State/Province"
                  options={provinceOptions}
                  onChange={value => handleFormValueChange("province", value)}
                  startingValue={getValues("province") || undefined}
                  {...commonProvinceProps}
                /> : 
                <Input 
                  label="State/Province"
                  value={getValues("province")}
                  onChange={value => handleFormValueChange("province", value)}
                  inputClass={style.inputField} 
                  locked={isEmptyDropDownValue(getValues("country"))}
                  {...commonProvinceProps}
                />
              }
            </div>
          </div>

          <div className={style.row}>
            <div className={style.rowColumnTwo}>
              <Input
                id='email'
                label="Email"
                register={register} 
                value={getValues("email")}
                onChange={(value) => handleFormValueChange("email", value)}
                onBlur={handleEmailBlur}
                placeholder="Placeholder" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
                validationSchema={emailValidationSchema}
              />
            </div>
            <div className={style.rowColumnTwo}>
              <Input
                id='phone'
                label="Telephone"
                register={register} 
                value={getValues("phone")}
                onChange={(value) => handleFormValueChange("phone", value)}
                placeholder="Placeholder" 
                inputClass={style.inputField} 
                labelClass={style.labels} 
                errors={errors} 
                type='tel'
                validationSchema={phoneValidationSchema}
              />
            </div>
          </div>
        </div>

        <div className={style.formSection}>
          <div className={style.title}>
            <h4><span>4</span> Applicant Documents</h4>
          </div>
          <div className={style.gridContainer}>
            <FileUpload
              id="passportUpload"
              label="Passport Bio Page (for all countries of Citizenship)"
              className={style.fileUpload}
              register={register}
              addDocuments={addDocuments}
              deleteDocument={deleteDocument}
              documentNames={getFieldDocumentNames(documentNames, "passportUpload")}
              errors={errors}
            />

            <FileUpload
              id="postSecondaryUpload"
              label="Post-Secondary Education Docs/Certificate"
              className={style.fileUpload}
              register={register}
              addDocuments={addDocuments}
              deleteDocument={deleteDocument}
              documentNames={getFieldDocumentNames(documentNames, "postSecondaryUpload")}
              errors={errors}
            />

            <FileUpload
              id="resumeUpload"
              label="Resume"
              subLabel="Include all work history, job titles, description of duties, locations, dates and if employment was full-time/part-time."
              className={style.fileUpload}
              subLabelClass={style.fileUploadSubtext}
              register={register}
              addDocuments={addDocuments}
              deleteDocument={deleteDocument}
              documentNames={getFieldDocumentNames(documentNames, "resumeUpload")}
              errors={errors}
            />

            <FileUpload
              id="canadianUpload"
              label="Canadian Status Document (if applicable)"
              subLabel="Include all current and/or previous, such as Work Permits, Study Permits, TRVs or Business Visas."
              className={style.fileUpload}
              subLabelClass={style.fileUploadSubtext}
              register={register}
              addDocuments={addDocuments}
              deleteDocument={deleteDocument}
              documentNames={getFieldDocumentNames(documentNames, "canadianUpload")}
              errors={errors}
            />

            <FileUpload
              id="marriageUpload"
              label="Marriage Certificate (if applicable)"
              className={style.fileUpload}
              register={register}
              addDocuments={addDocuments}
              deleteDocument={deleteDocument}
              documentNames={getFieldDocumentNames(documentNames, "marriageUpload")}
              errors={errors}
            />
          </div>

          <div className={style.rowColumnTwo}>
            <p className={style.familyMembers}>Accompanying Family Members' Documents</p>
          </div>

          <div className={style.gridContainer}>
            <FileUpload
              id="familyPassportUpload"
              label="Passport Bio Pages (for all family members)"
              className={style.fileUpload}
              register={register}
              addDocuments={addDocuments}
              deleteDocument={deleteDocument}
              documentNames={getFieldDocumentNames(documentNames, "familyPassportUpload")}
              errors={errors}
            />
            <FileUpload
              id="familyBirthCertUpload"
              label="Birth Certificates (for all dependant children)"
              className={style.fileUpload} 
              register={register}
              addDocuments={addDocuments}
              deleteDocument={deleteDocument}
              documentNames={getFieldDocumentNames(documentNames, "familyBirthCertUpload")}
              errors={errors}
            />
          </div>
        </div>


        <div className={style.rowColumnTwo}>
          <div className={style.submitSection}>
            <Button onClick={customValidation} id={'applicationSubmit'} className={style.submitButton} type="submit" style='gradient'>
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};