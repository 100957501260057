import CSVViewer from "components/UI/CSVViewer/CSVViewer";
import XLSXViewer from "components/UI/XLSXViewer/XLSXViewer";
import FileViewer from "react-file-viewer";

export const SMBFileTypes = {
  xlsxFile: {
    header: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    value: "xlsx",
  },
  csvFile: {
    header: "text/csv; charset=utf-8",
    value: "csv",
  },
  docFile: {
    header:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    value: "docx",
  },
  pdfFile: {
    header: "application/pdf",
    value: "pdf",
  },
  txtFile: {
    header: "text/plain; charset=utf-8",
    value: "txt",
  },
  pngFile: {
    header: "image/png",
    value: "png",
  },
  jpgFile: {
    header: "image/jpg",
    value: "jpg",
  },
  jpegFile: {
    header: "image/jpeg",
    value: "jpeg",
  },
  heicFile: {
    header: "image/heic",
    value: "heic",
  },
  zipFile: {
    header: "application/zip",
    value: "zip",
  }
};
  
export const AllowedExtensions = [
  SMBFileTypes.txtFile.value,
  SMBFileTypes.csvFile.value,
  SMBFileTypes.docFile.value,
  SMBFileTypes.jpegFile.value,
  SMBFileTypes.jpgFile.value,
  SMBFileTypes.pdfFile.value,
  SMBFileTypes.pngFile.value,
  SMBFileTypes.xlsxFile.value,
  SMBFileTypes.heicFile.value,
];
  
export const setImageFormat = (format: string | undefined) => {
  switch (format) {
    case SMBFileTypes.xlsxFile.header:
      return SMBFileTypes.xlsxFile.value;
    case SMBFileTypes.csvFile.header:
      return SMBFileTypes.csvFile.value;
    case SMBFileTypes.docFile.header:
      return SMBFileTypes.docFile.value;
    case SMBFileTypes.pdfFile.header:
      return SMBFileTypes.pdfFile.value;
    default:
      return SMBFileTypes.csvFile.value;
  }
};
  
export const formatImage = (format: string | undefined, smbImage: any) => {
  let image;
  switch (format) {
    case `text/plain; charset=utf-8`:
      image = <p style={{whiteSpace: "pre-wrap"}}>{smbImage}</p>;
      break;
    case `image/png`:
    case `image/jpg`:
    case `image/jpeg`:
      image = <div className="image-container" style={{textAlign: "center"}}><img src={`${smbImage}`} style={{maxWidth: "100%"}} /></div>;
      break;
    case SMBFileTypes.xlsxFile.header:
      image = <XLSXViewer file={smbImage} />;
      break;
    case SMBFileTypes.csvFile.header:
      image = <CSVViewer file={smbImage} />;
      break;
    case SMBFileTypes.docFile.header:
    case SMBFileTypes.pdfFile.header:
      image = (
        <div className="file-viewer-container" style={{width: "100%", textAlign: "center"}}>
          <FileViewer fileType={setImageFormat(format)} filePath={smbImage} />
        </div>
      );
      break;
    default:
      image = (
        <div className="file-viewer-container" style={{width: "100%", textAlign: "center"}}>
          <FileViewer fileType={setImageFormat(format)} filePath={smbImage} />
        </div>
      );
  }
  return image;
};
  