import { loginUser, setCookie, getCookie } from 'actions/userActions';
import { useSetAppState, useAppState, useMergeAppState } from 'context';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/UI/button/button';
import { TextField } from 'components/UI/textField/textField';
import styles from './loginForm.module.scss';
import { RoutePaths } from 'app/routing/routing';
import { useForm, SubmitHandler } from "react-hook-form";
import PasswordComponent from 'components/UI/passwordComponent/passwordComponent';
import { useKeyDownTrigger } from 'hooks/useKeyDownTrigger';

const useOnLogin = () => {
  const setAppState = useSetAppState();
  const mergeAppState = useMergeAppState();

  const onSubmit: SubmitHandler<LoginFormFields> = async (data) => {
    mergeAppState({ Authenticating: true });
    const { email, password, rememberMe } = data;
    try {
      const loginResult = await loginUser({email, password});

      if (rememberMe) {
        setCookie(email);
      }
      mergeAppState({ Authenticated: false, Authenticating: true, LoginResult: loginResult });
        
      return true;
    } catch(err) {
      mergeAppState({ Authenticated: false, Authenticating: false });
    }
  };

  return onSubmit;
};

export interface LoginFormFields {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const LoginFormComponent = () => {
  const { register, handleSubmit, formState: { errors }, setError, setValue, trigger } = useForm<LoginFormFields>();
  const appState = useAppState();
  const history = useHistory();
  const emailFromCookie = getCookie();
  const onLogin = useOnLogin();

  const onChangeFormField = (field: keyof LoginFormFields, value: LoginFormFields[keyof LoginFormFields]) => {
    setValue(field, value);
    trigger(field);
  };

  const onSubmit = async (values: LoginFormFields) => {
    if (!appState.Authenticated) {
      try {
        const validLogin = await onLogin(values);

        if (validLogin) {
          history.push(RoutePaths.TwoFactor);
        } else {
          setError('password', {
            type: 'manual',
            message: 'Invalid User Name or Password',
          });
        }
        
      } catch (err) {
        console.error(err);
      }
    }
  };

  const historyPassword = useHistory();

  const onViewClick = () => { 
    historyPassword.push(RoutePaths.ForgotPassword); 
  };

  useKeyDownTrigger(handleSubmit(onSubmit));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField 
        id="email" 
        register={register} 
        type="email" 
        placeholder="User Name" 
        label="User Name" 
        className={styles.field} 
        labelClassName={styles.labels} 
        errors={errors} 
        startingValue={emailFromCookie} 
        onChange={value => onChangeFormField("email", value)}
        validationSchema={{
          required: "Email is required",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format",
          }
        }} 
      />
      <PasswordComponent
        register={register}
        name="password"
        placeholder="Password"
        className={styles.field}
        label="Password"
        errors={errors}
        onChange={value => onChangeFormField("password", value)}
        validationSchema={{ 
          required: "Password is required",
        }}
      />
      <div className={styles.loginMiscButtons}>
        <input type="checkbox" id="rememberMe" {...register("rememberMe")}/>
        <label htmlFor="rememberMe">Remember me</label>
      </div>
      <div className={styles.loginButtonSection}>
        <Button className={styles.loginButton} type="submit">
          Log in
        </Button>
      </div>
      <div className={styles.forgotPass}>
        <a id={'forgotPassword'} className={styles.forgot} onClick={onViewClick}>Forgot Password?</a>
      </div>
    </form>
  );
};