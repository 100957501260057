import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import styles from './CSVViewer.module.scss';
import classNames from 'classnames';

interface CSVViewerProps {
  file: string;
}

interface RowData {
  [key: string]: string | number;
}

const CSVViewer: React.FC<CSVViewerProps> = ({ file }) => {
  const [rows, setRows] = useState<RowData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(file);
        const text = await response.text();
        const result = Papa.parse<RowData>(text, {
          header: true,
          skipEmptyLines: true,
          dynamicTyping: true,
        });
        setRows(result.data);
      } catch (error) {
        console.error('Error reading CSV file:', error);
      }
    };

    fetchData();
  }, [file]);

  return (
    <div className={classNames(styles.csvViewer)}>
      {rows.length > 0 && (
        <table className={classNames(styles.table)}>
          <thead>
            <tr>
              {Object.keys(rows[0]).map(key => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, idx) => (
                  <td key={idx}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CSVViewer;
