import React, { useState } from 'react';
import { Button } from "components/UI/button/button";
import DownLoadArrow from 'components/icons/downloadArrow';
import styles from "./dashboardDocumentRow.module.scss";
interface IDashboardDocumentRow {
  index: number;
  docDesc: string;
  uploadedBy?: string;
  created: string;
  onRowClick?: () => void;
  onDownload?: (e: React.MouseEvent) => void;
}

export const DashboardDocumentRow = ({
  index,
  docDesc,
  uploadedBy,
  created,
  onRowClick = () => undefined,
  onDownload = () => undefined,
}: IDashboardDocumentRow) => {
  const [isDownloadingLocal, setIsDownloadingLocal] = useState(false);

  const handleDownload = async (e: React.MouseEvent) => {
    setIsDownloadingLocal(true);
    try {
      await onDownload(e);
    } finally {
      setIsDownloadingLocal(false);
    }
  };

  return (
    <>
      <tr className={styles.selectableRow} onClick={onRowClick}>
        <td className={styles.textTitle}>{docDesc}</td>
        <td className={styles.headers}>{uploadedBy}</td>
        <td className={styles.headers}>{created}</td>
        <td>
          <Button
            id={"downloadDocument"}
            onClick={(e) => {
              e.stopPropagation();
              handleDownload(e);
            }}
            className={styles.viewDocument}
            mobileText={isDownloadingLocal ? "Downloading..." : "Download"}
            disabled={isDownloadingLocal}
          >
            <div className={styles.buttonTitle}>
              {isDownloadingLocal ? "Downloading..." : "Download"}
            </div>  
            {!isDownloadingLocal && <DownLoadArrow />}
          </Button>
        </td>
      </tr>
    </>
  );
};