import { useEffect, useState } from "react";
import styles from "./documents.module.scss";
import Layout from "components/layout/Layout";
import { Filter } from "components/UI/filter/filter";
import { SearchInput } from "components/UI/searchInput/searchInput";
import { AllowedExtensions, SMBFileTypes } from "utils/imageFunctions";
import passport from "../../assets/svgs/document-thumbnail.svg";
import { Button } from "components/UI/button/button";
import NoDataMessage from "components/noDataBlock/noDataMessage";
import { uploadSMBDocument, useGetDocuments } from "actions/documentActions";
import { Modal } from "components/modal/modal";
import DropDownSelect from "components/UI/dropDownSelect/dropDownSelect";
import SingleFilterDropDown, { OptionItem } from "components/UI/singleFilterDropDown/singleFilterDropDown";
import { useGetFileNumbers } from "actions/applicationsActions";
import FileUpload, { DocumentAdditionParams, FileUploadStyle } from "components/UI/FileUpload/fileUpload";
import { fileToBase64, parseFileExtensions } from "utils/commonFunctions";
import { useForm } from 'react-hook-form';
import { DocumentCard } from "components/documentCard/documentCard";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";
import useScreenWidth from "hooks/useScreenWidth";

const filterOptions = [
  {
    id: "IMAGE",
    label: "Images",
  },
  {
    id: "PDF",
    label: "PDF",
  },
  {
    id: "SPREADSHEET",
    label: "Spreadsheet",
  },
  {
    id: "DOCUMENT",
    label: "Word/Documents",
  },
  {
    id: "SHELL",
    label: "Other",
  },
];

const sortOptions: OptionItem[] = [
  {
    label: "Date Uploaded - Newest to Oldest",
    value: { orderBy: "created", sortOrder: "DESC" },
  },
  {
    label: "Date Uploaded - Oldest to Newest",
    value: { orderBy: "created", sortOrder: "ASC" },
  },
  { 
    label: "Document Name - Alphabetical (A to Z)",
    value: { orderBy: "docDesc", sortOrder: "ASC" },
  },
  {
    label: "Document Name - Alphabetical (Z to A)",
    value: { orderBy: "docDesc", sortOrder: "DESC" },
  },
  {
    label: "Matter Number - Newest to Oldest",
    value: { orderBy: "fileNo", sortOrder: "DESC" },
  },
  {
    label: "Matter Number - Oldest to Newest",
    value: { orderBy: "fileNo", sortOrder: "ASC" },
  },
];

interface UploadQueue {
  files: File[],
  success: boolean;
}

export interface DocumentFormFields {
  documentPageFileUpload: string;
}

const UPLOAD_FIELD_ID = "documentPageFileUpload" as keyof DocumentFormFields;

export const Documents = () => {
  const [showUploadDocumentsModal, setShowUploadDocumentsModal] = useState(false);
  const [showUploadDocumentsModalElements, setShowUploadDocumentsModalElements] = useState(true);
  const [searchWord, setSearchWord] = useState<string>("");
  const [orderBy, setOrderBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [docType, setDocType] = useState<string[]>([]);
  const [isFilterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState<string>("");
  const [uploadQueue, setUploadQueue] = useState<UploadQueue>({ files: [], success: false });
  const [prevUploads, setPrevUploads] = useState<File[]>([]);
  const { register, handleSubmit, formState: { errors }, setError, clearErrors, setValue, getValues, trigger } = useForm<DocumentFormFields>(); 
  const screenWidthForMobile = 1024;
  const screenWidth = useScreenWidth();
  const [dropDownSearchWord, setDropDownSearchWord] = useState<string>();
  const [uploadMessages, setUploadMessages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  
  const { 
    data: documentData, 
    error: documentDataError, 
    mutate: mutateDocuments, 
    noDataFound: noDocumentDataFound,
    isValidating,
    size,
    setSize,
    hasMore,
  } = useGetDocuments({ searchWord, rowNumber: 100, orderBy, sortOrder, docType });

  const { data: fileNumberOptions, error: fileNumberError } = useGetFileNumbers(dropDownSearchWord);

  useEffect(() => {
    if (uploadQueue.files.length > 0) {
      setPrevUploads(uploadQueue.files);
    }
  }, [uploadQueue]);

  const openUploadDocumentsModal = () => {
    setUploadMessages([]);
    setShowUploadDocumentsModal(true);
    setShowUploadDocumentsModalElements(true);
  };

  const handleCloseModal = () => {
    setShowUploadDocumentsModal(false);
    setSelectedFileId("");
    setPrevUploads([]);
    clearErrors(UPLOAD_FIELD_ID);
    setUploadQueue({ files: [], success: false });
  };

  const handleSortChange = (value: any) => {
    setOrderBy(value?.orderBy || "");
    setSortOrder(value?.sortOrder || "");
    resetData();
  };

  const handleCheckboxChange = (value: any) => {
    setDocType(value);
    resetData();
  };

  const onSearch = async (value: string) => {
    setSearchWord(value);
    resetData();
  };

  const onChangeFileNumber = (value: string) => {
    setUploadQueue({ ...uploadQueue, success: false });
    clearErrors(UPLOAD_FIELD_ID);
    setSelectedFileId(value);
    resetData();
  };

  const uploadDocument = async (file: File): Promise<{ messages: string[] }> => {
    if (!selectedFileId || !file) return { messages: [] };
  
    const rawBase64 = await fileToBase64(file);
    const base64RemoveExtension = rawBase64.split(',')[1];
  
    try {
      const response = await uploadSMBDocument(selectedFileId, file.name, base64RemoveExtension);
      return { messages: response.messages || [] };
    } catch (error) {
      return { messages: ['An error occurred during the upload. Please try again.'] };
    }
  };

  const resetUploadQueue = () => {
    setUploadQueue({ files: [], success: false });
  };

  const addDocuments = async ({ files, fieldId }: DocumentAdditionParams) => {
    try {
      clearErrors(UPLOAD_FIELD_ID as keyof DocumentFormFields);
      setUploadMessages([]);
      setIsUploading(true);
  
      
      const { supportedFiles, error } = parseFileExtensions(files, [...AllowedExtensions, SMBFileTypes.zipFile.value]);
      setUploadQueue({ files: supportedFiles, success: false });
  
      if (error) {
        setError(fieldId as keyof DocumentFormFields, error);
      }
      if (supportedFiles.length === 0) return;
  
      
      const uploadResults = await Promise.all(supportedFiles.map(async (file) => {
        return uploadDocument(file);
      }));
  
      
      const backendErrorMessages = uploadResults.flatMap(result => result.messages || []);
      
      if (backendErrorMessages.length > 0) {
        setUploadMessages(backendErrorMessages);
        setShowUploadDocumentsModalElements(true);
      } else {
        // If everything is successful
        setUploadQueue({ files: [], success: true });
        setShowUploadDocumentsModalElements(false);
      }
    } catch (error) {
      const errorMessage = {
        type: "fileType",
        message: `Upload failed. Please try again.`
      };
      setError(fieldId as keyof DocumentFormFields, errorMessage);
      resetUploadQueue();
      setShowUploadDocumentsModalElements(true);
    }
    finally {
      setIsUploading(false);
      mutateDocuments();
    }
  };

  const resetData = () => {
    setSize(1);
    mutateDocuments();
  };

  const loadMore = () => {
    setSize(prevSize => prevSize + 1);
  };

  const [loader] = useInfiniteScroll(loadMore, hasMore, [orderBy, sortOrder, searchWord, docType]);

  return (
    <Layout>
      <div className={styles.documents}>
        <h2>Your Documents</h2>
      </div>
      <div className={styles.filterButtons}>
        <Button id={'uploadDocuments'} style='upload' mobileText="New" className={styles.uploadButton} onClick={openUploadDocumentsModal}>Upload Documents</Button>
        <Filter id={'filterDocuments'} text="Filter documents" filterOptions={filterOptions} isVisible={isFilterDropdownVisible} onCheckboxChange={handleCheckboxChange} setVisible={setFilterDropdownVisible} className={styles.filter} mobileText="Filter"/>
        <SingleFilterDropDown 
          id={'sortDocuments'} 
          label={"Sort documents"} 
          optionItems={sortOptions} 
          onOptionSelected={handleSortChange}
          dropDownDirectionClass={styles.dropDownDirectionClass}
          mobileText="Sort"
        />
        <SearchInput id={'searchDocuments'} placeholder="Try 'work passport'" onChange={onSearch} className={styles.searchBar}/>
      </div>

      <Modal 
        id="documents" 
        title="Upload Documents" 
        show={showUploadDocumentsModal} 
        onClose={handleCloseModal} 
        headerClass={styles.modalHeader}
        className={styles.uploadDocumentModal}
        hideExitButton={false}>
        <div className={styles.modalContent}>
          
          {showUploadDocumentsModalElements && <div className={styles.applicationNumberContainer}>
            <DropDownSelect
              id="applicationNumbers"
              dropDownClass={"documents"}
              options={fileNumberOptions}
              onChange={onChangeFileNumber} 
              placeholder={(screenWidth > screenWidthForMobile) ? `Select Matter Number` : `Select Matter No.`}
              maxHeight={100} // sets maxHeight to 100px
              locked={isUploading}
              searchable
              refetch={setDropDownSearchWord}
            />
          </div>}
          
          {showUploadDocumentsModalElements && <div className={styles.uploadDocumentContainer}>
            <FileUpload 
              id={UPLOAD_FIELD_ID}
              label={"+ Upload Documents"}
              addDocuments={addDocuments}
              locked={!selectedFileId || isUploading}
              style={FileUploadStyle.BlueBubble}
              register={register}
            />
          </div>}

          <div className={styles.uploadTextContainer}>
            {uploadQueue.success && !errors.documentPageFileUpload && <p className={styles.uploadCompleteText}>{`Document Upload Complete!`}</p>}
            {errors && errors.documentPageFileUpload && <p className={styles.error}>{errors.documentPageFileUpload.message}</p>}
            {!uploadMessages || uploadMessages.length === 0 ? undefined : <div className={styles.uploadMessagesContainer}>
              <p className={styles.error}>Parts of your zipped folder failed to upload! The following document(s) have file types that are not supported by our portal.</p>
              {uploadMessages && uploadMessages.map((message, idx) => <p key={`uploadMessage_${idx}`} className={styles.error}>{message}</p>)}
            </div>}
          </div>
        </div>
      </Modal>

      <div className={styles.documentContainerWrapper}>
        <div id={"documentsContainer"} className={styles.documentContainer}>
          {noDocumentDataFound ? (
            <div className={styles.noDocsWrapper}>
              <NoDataMessage
                title="No Documents Found!"
                description="Please click “Upload Documents” to add a new document."
              />
            </div>
            
          ) : (
            documentData.map(doc =>
              <DocumentCard
                key={doc.docId}
                id={doc.docId}
                thumbnail={<img src={passport} alt="Document Thumbnail" />}
                title={doc.docDesc}
                date={doc.created}
                number={doc.fileNo}
                filePath={doc.docFileName}
              />
            )
          )}
          <div ref={loader} style={{ height: '1px', backgroundColor: 'transparent' }} />
        </div>
        {isValidating && documentData && size > 1 && <div>Loading...</div>}
        {size === 1 && isValidating &&  <LoadingContainer loadingLabel="Loading documents..." />}
      </div>
    </Layout>
  );
};
