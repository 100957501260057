import { Section, TableQuestionRecord } from "actions/questionnaireActions";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import styles from "./questionRow.module.scss";
import Question, { QuestionDataType } from "components/questionnaire/questionnaireComponents/question/question";
import { UpdateGridQuestionParams, UpdateTableQuestionParams } from "components/questionnaire/questionnaireHook";
import { getFieldId } from "utils/commonFunctions";

export type QuestionRowProps = {
  gridId: string;
  questions: QuestionDataType[];
  section: Section;
  selectedRecordData: TableQuestionRecord;
  updateGridQuestion: (params: UpdateGridQuestionParams) => void;
  register: UseFormRegister<any>;
  errors?: FieldErrors<any>;
  recordId: number;
  updateTableQuestion: (params: UpdateTableQuestionParams) => void;
  locked: boolean;
};

export const QuestionRow = ({
  gridId,
  questions,
  section,
  selectedRecordData,
  updateGridQuestion,
  register,
  errors,
  recordId,
  updateTableQuestion,
  locked,
}: QuestionRowProps) => {

  return (
    <div key={gridId} className={styles.questionRow}>
      {questions.map((question) => {
        const width = question.width !== 0 ? `${question.width}` : "100";
        const value =
          selectedRecordData.questions.find((sectionQuestion) => sectionQuestion.questionID.toString() === question.id)?.value || "";
        return (
          <div
            key={question.id}
            className={styles.question}
            style={{
              width: `${width}%`,
              height: "auto",
            }}
          >
            <Question
              fieldId={getFieldId({ label: question.label, sectionId: section.sectionID, recordId: recordId, isTableQuestion: true, isTablePreviewQuestion: false })}
              sectionId={section.sectionID}
              question={{ ...question, value: value }}
              updateGridQuestion={updateGridQuestion}
              register={register}
              recordId={recordId}
              updateTableQuestion={updateTableQuestion}
              errors={errors || {}}
              isReview={false}
              isTablePreviewQuestion={false}
              locked={locked}
            />
          </div>
        );
      })}
    </div>
  );
};
