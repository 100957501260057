import { useState } from "react";
import style from "./allMatters.module.scss";
import Layout from "components/layout/Layout";
import { Filter } from "components/UI/filter/filter";
import { SearchInput } from "components/UI/searchInput/searchInput";
import { CompanyTableRow } from "components/companyTableRow/companyTableRow";
import { BackArrow } from "components/UI/backArrow/backArrow";
import { useHistory } from "react-router-dom";
import { RoutePaths } from "app/routing/routing";
import { useGetApplications } from "actions/applicationsActions";
import NoDataMessage from "components/noDataBlock/noDataMessage";
import { IntStatus } from "utils/commonFunctions";
import SingleFilterDropDown, { OptionItem } from "components/UI/singleFilterDropDown/singleFilterDropDown";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { LoadingContainer } from "components/UI/loadingContainer/loadingContainer";

const intStatusOptions = [
  {id: '0', label: 'Active'},
  {id: '2', label: 'Closed'},
  {id: '3', label: 'On Hold'},
  {id: '4', label: 'Pending Review'},
  {id: '1', label: 'Submitted'},
];

const sortOptions: OptionItem[] = [
  {
    label: "Matter Number - Highest to Lowest",
    value: { orderBy: "fileNo", sortOrder: "DESC" },
  },
  {
    label: "Matter Number - Lowest to Highest",
    value: { orderBy: "fileNo", sortOrder: "ASC" },
  },
  {
    label: "Applicant Name - Alphabetical (A to Z)",
    value: { orderBy: "ApplicantName", sortOrder: "ASC" },
  },
  {
    label: "Applicant Name - Alphabetical (Z to A)",
    value: { orderBy: "ApplicantName", sortOrder: "DESC" },
  },
  {
    label: "Status - Alphabetical (A to Z)",
    value: { orderBy: "intStatus", sortOrder: "ASC" },
  },
  {
    label: "Status - Alphabetical (Z to A)",
    value: { orderBy: "intStatus", sortOrder: "DESC" },
  },
];

export const AllMatters = () => {
  const history = useHistory();
  const [searchWord, setSearchWord] = useState<string>("");
  const [orderBy, setOrderBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [intStatus, setIntStatus] = useState<Array<IntStatus>>([]);
  const [isFilterDropdownVisible, setFilterDropdownVisible] = useState(false);
  
  const { 
    data: applicationsData, 
    error: applicationsError, 
    mutate: mutateApplications, 
    noDataFound: noApplicationsDataFound,
    isValidating,
    size,
    setSize,
    hasMore,
  } = useGetApplications({ 
    rowNumber: 100,
    searchWord: searchWord,
    orderBy: orderBy,
    sortOrder: sortOrder,
    intStatus: intStatus, 
  });

  const handleSortChange = (value: any) => {
    setOrderBy(value?.orderBy || "");
    setSortOrder(value?.sortOrder || "");
    resetData();

  };

  const onSearch = (value: string) => {
    setSearchWord(value);
    resetData();
  };

  const onFilterChange = async (selectedOptions: any[]) => {
    setIntStatus(selectedOptions as IntStatus[]);
    resetData();
  };

  const onViewClick = (
    clientNumber: number,
    fileId: string
  ) => {
    history.push({
      pathname: `${RoutePaths.Company}/${clientNumber}${RoutePaths.Matter}/${fileId}`
    });
  };  


  const resetData = () => {
    setSize(1);
    mutateApplications();
  };

  const loadMore = () => {
    setSize(prevSize => prevSize + 1);
  };

  const [loader] = useInfiniteScroll(loadMore, hasMore, [orderBy, sortOrder, searchWord, intStatus]);


  return (
    <Layout>
      <div className={style.company}>
        <h2 className={style.headerDesktop}>
          Your Matters
        </h2>
        <h2 className={style.headerMobile}>All Matters</h2>
      </div>
      <div className={style.filterButtons}>
        <Filter
          text="Filter Matters"
          isVisible={isFilterDropdownVisible}
          setVisible={setFilterDropdownVisible}
          onCheckboxChange={onFilterChange}
          filterOptions={intStatusOptions}
          mobileText="Filter"
          className={style.filter}
        />
        <SingleFilterDropDown 
          id={'sortMatters'} 
          label={"Sort Matters"} 
          mobileText={"Sort"}
          optionItems={sortOptions} 
          onOptionSelected={handleSortChange}
          dropDownDirectionClass={style.dropDownDirectionClass}
        />
        <SearchInput
          placeholder="Try 'work permit'"
          onChange={onSearch}
          className={style.searchBar}
        />
      </div>
      <div className={style.clientTableContainerWrapper}>
        <div className={style.clientTableContainer}>
          <div>
            <table className={style.clientTable}>
              <thead>
                <tr>
                  <th>Matter Number</th>
                  <th>Client Name</th>
                  <th className={style.description}>Matter Description</th>
                  <th className={style.description}>Lawyer</th>
                  <th className={style.description}>Created On</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {applicationsData &&
                  applicationsData.filter(application => application !== null)?.map((applicationRowData, index) => {
                    return (
                      <CompanyTableRow
                        key={index}
                        number={applicationRowData.fileNo}
                        company={applicationRowData.applicantName}
                        description={applicationRowData.fileDesc}
                        status={applicationRowData.fileStatus}
                        lawyer={applicationRowData.lawyer}
                        createdOn={applicationRowData.createdOn}
                        onClick={() =>
                          onViewClick(
                            applicationRowData.fileNo,
                            applicationRowData.fileId
                          )
                        }
                      />
                    );
                  })}
                {isValidating && applicationsData && size > 1 &&<tr><td><span>Loading...</span></td></tr>}

              </tbody>
            </table>
            <div ref={loader} style={{ height: '1px', backgroundColor: 'transparent' }} />
            {size === 1 && isValidating &&  <LoadingContainer loadingLabel="Loading matters..." />}
          </div>
          {noApplicationsDataFound && !isValidating &&(
            <NoDataMessage
              title="No Matters Found!"
              description="Please reach out to your primary contact at the firm for assistance."
            />
          )}
        </div>
      </div>
    </Layout>
  );
};
