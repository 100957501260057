import { FC, useEffect } from "react";
import { LoginFormComponent } from '../../components/loginForm/loginForm';
import style from "./login.module.scss";
import LoginGraphic from 'components/icons/loginGraphics/loginGraphic';
import logo from 'assets/images/company-logo.png';
import SimpleLayout from 'components/simpleLayout/simpleLayout';
import { LoginGraphicContainer } from 'components/loginGraphicContainer/loginGraphicContainer';
import { useAppState, useSetAppState } from 'context';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'app/routing/routing';

export const Login: FC = () => {
  const appState = useAppState();
  const history = useHistory();
  const setAppState = useSetAppState();

  useEffect(() => {
    if (!appState.Authenticating) {
      setAppState({
        Authenticated: false,
        Authenticating: false,
        LoginResult: undefined,
        isMagicLinkAccess: false,
        twoFactorRequired: false
      });
    }
  }, []);

  useEffect(() => {
    if (appState.Authenticated && !appState.twoFactorRequired) {
      history.replace(RoutePaths.Dashboard);
    } else if (appState.twoFactorRequired) {
      history.replace(RoutePaths.TwoFactor);
    }
  }, [appState.Authenticated, appState.twoFactorRequired]);

  return (
    <SimpleLayout>
      <div className={style.content}>
        <div className={style.loginContent}>
          <div className={style.logo}>
            <img className={style.companyLogo} src={logo} alt="Company logo" />
          </div>
          <div className={style.loginModal}>
            <h3 className={style.loginTitle}>Log in</h3>
            <LoginFormComponent />
          </div>
        </div>
        <LoginGraphicContainer Graphic={LoginGraphic} />
      </div>
    </SimpleLayout>
  );
};
